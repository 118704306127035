import {
    ROUTE_CHANGED,
} from "../constants/ActionTypes";


const INIT_STATE = {
    routeChanged: null,
    currentRoute: null

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ROUTE_CHANGED: {

            return {
                ...state,
                routeChanged: new Date().getTime(),
                currentRoute: action.payload
            }
        }

        default:
            return state;
    }
}
