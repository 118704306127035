import ApiURL from "./ApiURL";
import axios from "axios";
import React from "react";
import configureStore from '../store';


export function getSubDomain($default = null) {
    var full = window.location.host
//window.location.host is subdomain.domain.com
    var parts = full.split('.');
    var sub = parts[0];
    var domain = parts[1];
    var type = parts[2];
//sub is 'subdomain', 'domain', type is 'com'
    return !$default ? sub : $default;
}


export const isOnline = () => {
    return process.env.NODE_ENV === "production";
}
const state = () => {
    return configureStore().getState();
};


const config = (api, fun) => {
    const _config = {
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Credentials': true,
            'content-type': 'multipart/form-data',
        },
    }

    return _config;
}


export function getAllProducts() {
    const api = ApiURL.getProducts;
    return axios.get(api, config(api, "getAllProducts"));
}

export function getAllNewsLetters() {
    const api = ApiURL.getNewsLetters;
    return axios.get(api, config(api, "getAllNewsLetters"));
}

export function getAllCareers() {
    const api = ApiURL.getCareers;
    return axios.get(api, config(api, "getAllCareers"));
}

export function getAllCategories() {
    const api = ApiURL.getCategories;
    return axios.get(api, config(api, "getCategories"));
}


export function getSettingsInfo() {
    const api = ApiURL.getSettingsInfo;

    return axios.get(api, config(api, "getSettingsInfo"));


}

export function validatingJSON(json) {
    var checkedjson
    try {
        checkedjson = JSON.parse(json)
    } catch (e) {
    }
    return checkedjson
}

export function consoleLog(body, functionName) {

    console.log(functionName);
    console.log(body);
}

export function ucfirst(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
}


export function filterCategories(categoriesList, authUser) {
    return categoriesList.filter((cat) => {
        // if (authUser.type != "senior") {
        //     if (authUser.agentExist) {
        //         if (authUser.type == "admin") {
        //             return !cat.isOffer;
        //         } else {
        //             return cat.isOffer;
        //         }
        //     }
        // }

        return true;
    })
}


export function checkParams(v, params, def = "") {
    let results = v;


    for (let x = 0; x < params.length; x++) {
        const key = params[x];

        if (typeof results == "object" && results.hasOwnProperty(key)) {
            results = results[key]

        }
    }


    return typeof results == 'string' && results.length > 0 ? results : def;
}

export const getUserLocation = (setLat, setLng) => {
    const location = window.navigator && window.navigator.geolocation

    if (location) {
        location.getCurrentPosition((position) => {
            setLat(position.coords.latitude);
            setLng(position.coords.longitude);
        }, (error) => {
            console.log({latitude: 'err-latitude', longitude: 'err-longitude'})
        })
    }

}


export const _onClickLink = (url, route_change, currentRoute, history, func = null) => {

    if (currentRoute === url || !url) return;
    route_change(url);
    document.getElementById("check").checked = false;
    setTimeout(() => {
        history.push(url);

        func && func();
    }, 750);

}
