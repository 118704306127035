import React, {useEffect, useState} from 'react';
import './production.scss';
import {connect} from "react-redux";
import {FooterStates, PageStates} from "../../../../reducers/connection/states";
import {FooterDispatch, PageDispatch} from "../../../../reducers/connection/functions";
import Images from "../../../../constants/Images";
import {FormattedMessage} from "react-intl";

const {
    fireball,
    tonic,
    tazech,
    karwnachi,
    brand1,
    brand2,
    brand3,
    brand4,
    brand5
} = Images.home;


const ProductionLine = ({children, change_theme,routeChanged,match,isDirectionRTL,currentRoute, themeColor, themeFooterBackgroundGradient, themeFooterLineGradient, themePageBackgroundGradient}) => {

    const [fadeInAnimation,setFadeInAnimation] = useState("fadeInUp");


    useEffect(() => {
        if(routeChanged && currentRoute != match.path){
            setFadeInAnimation("fadeOutDown");
        }
    }, [routeChanged]);

    const arabicClass = isDirectionRTL ? "page-contents page-contents-rtl" : "page-contents page-contents-ltr";

    return (
        <div className={"container "+arabicClass} id={"about-us"}>

            <div className={"row details-section animated fast "+fadeInAnimation} id={"title-section"}>
                <div className={"col-lg-12 col-md-12 col-sm-12 "}>
                    <FormattedMessage tagName={"h1"} id="home.productionLineTitle" />
                </div>
            </div>

            <div className={"row details-section animated fast "+fadeInAnimation} id={"goals-section"}>
                <div className={"col-lg-6 col-md-12 col-sm-12 section"}>
                    <FormattedMessage id="home.productionLineLongDescription_1">
                        {text=>
                            <span dangerouslySetInnerHTML={{__html: text}} />
                        }
                    </FormattedMessage>
                </div>
                <div className={"col-lg-6 col-md-12 col-sm-12 section"}>
                    <FormattedMessage id="home.productionLineLongDescription_2">
                        {text=>
                            <span dangerouslySetInnerHTML={{__html: text}} />
                        }
                    </FormattedMessage>
                </div>
            </div>
        </div>

    )
}


export default connect(PageStates, PageDispatch)(ProductionLine);
