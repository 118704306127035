import React, {useState, useEffect, useRef} from 'react';
import './navbar.scss';
import IntlMessages from '../../../util/IntlMessages';
import {connect} from "react-redux";
import {NavBarStates} from "../../../reducers/connection/states";
import {NavBarDispatch} from "../../../reducers/connection/functions";
import {Reorder} from '@material-ui/icons';
import {animated, useChain, useTransition} from "react-spring";
import {NavLink, Redirect} from "react-router-dom";
import Images from "../../../constants/Images";
import LanguageOptions from "../LanguageOptions";
import {_onClickLink} from "../../../constants/Helper";

const NavBar = ({categories, locale, location, change_display_mode, select_category, isDirectionRTL, selectedProduct, selectedCategory, route_change, currentRoute, change_theme, history}) => {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerWidth);
    const [links, setLinks] = useState([]);

    const pageName = location.pathname.split("/")[1];

    useEffect(() => {
        window.addEventListener('resize', (r) => {
            setScreenWidth(r.target.innerWidth);
            setScreenHeight(r.target.innerHeight);
        });

        createLinksArray();

    }, [categories, locale]);


    useEffect(() => {
        if (selectedProduct) {

        }
    }, []);

    const onClickLink = (url, func = null) => {

        _onClickLink(url, route_change, currentRoute, history,func);

    }

    const createLinksArray = () => {
        const categories_list = [];

        categories && Array.isArray(categories) && categories.map((category) => {
            const name = category.category_name[locale.locale];

            categories_list.push({
                label: name,
                url: "/category/" + category.slug,
                onClick: () => {
                    select_category(category);
                    // change_display_mode(false);
                    // change_theme();
                }
            })
        })

        const menuLinks = [
            {
                label: "Home",
                id: "menu.links.home",
                url: "/"
            },
            {
                label: "About",
                id: "menu.links.about",
                url: "/about"
            },
            {
                label: "Products",
                id: "menu.links.products",
                url: currentRoute,
                links: [
                    ...categories_list
                ]
            },
            {
                label: "Careers",
                id: "menu.links.careers",
                url: "/careers"
            },


            {
                label: "Contact Us",
                id: "menu.links.contact",
                url: "/contact"
            },
            {
                label: "Languages",
                Component: LanguageOptions,
            },
        ]


        setLinks(menuLinks);
    };

    const transRef = useRef()
    const transitions = useTransition(links, item => item.label, {
        ref: transRef,
        unique: true,
        trail: 1000 / links.length,
        from: {opacity: 0, transform: 'scale(0)'},
        enter: {opacity: 1, transform: 'scale(1)'},
        leave: {opacity: 0, transform: 'scale(0)'}
    })

    useChain([transRef], [0]);


    const arabicClass = isDirectionRTL ? "navBar navBarRTL" : "navBar navBarLTR";

    const product_info_open = isDirectionRTL ? "product_info_open_RTL" : "product_info_open_LTR";

    const red = pageName == "category" && selectedCategory && selectedCategory.slug == "fire_ball";

    return (
        <header className={arabicClass}>
            <input type="checkbox" id="check"/>
            <label htmlFor="check" className="checkbtn">
                <Reorder fontSize={"large"}/>
            </label>
            <label className="logo bounceIn delay-0.5"><img
                src={red ? Images.redLogo : Images.logo}/></label>
            <ul className={selectedProduct ? "main-navBar " + product_info_open : "main-navBar"}>
                {links.map((item, key) => !item.hasOwnProperty("Component") ? (

                    <li onClick={() => {
                        onClickLink(item.url)
                    }} className={"animated bounceIn delay-1"} id={item.id} key={key}>

                        <IntlMessages id={item.id}/>
                        {item.hasOwnProperty("links") &&
                        <div id={key} className={"menu-dropdown-list"}>
                            <ul>
                                {item.hasOwnProperty("links") && item.links.map((_item) => {
                                    return (
                                        <li onClick={() => {
                                            onClickLink(_item.url, _item.hasOwnProperty("onClick") && _item.onClick);


                                        }}>{_item.label}</li>
                                    )
                                })}
                            </ul>
                        </div>
                        }

                    </li>
                ) : (<li id={item.id} key={key}>
                    <item.Component/>
                </li>))}
            </ul>
        </header>
    )
};


export default connect(NavBarStates, NavBarDispatch)(NavBar);
