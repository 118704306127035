import React, {useEffect, useState} from 'react';
import './about.scss';
import {connect} from "react-redux";
import {FooterStates, PageStates} from "../../../../reducers/connection/states";
import {FooterDispatch, PageDispatch} from "../../../../reducers/connection/functions";
import Images from "../../../../constants/Images";
import {FormattedMessage} from "react-intl";

const {
    fireball,
    tonic,
    tazech,
    karwnachi,
    brand1,
    brand2,
    brand3,
    brand4,
    brand5
} = Images.home;


const About = ({children, change_theme,isDirectionRTL,routeChanged,match,currentRoute, themeColor, themeFooterBackgroundGradient, themeFooterLineGradient, themePageBackgroundGradient}) => {

    const [fadeInAnimation,setFadeInAnimation] = useState("fadeInUp");


    useEffect(() => {
        if(routeChanged && currentRoute != match.path){
            setFadeInAnimation("fadeOutDown");
        }
    }, [routeChanged]);
    const arabicClass = isDirectionRTL ? "page-contents page-contents-rtl" : "page-contents page-contents-ltr";


    return (
        <div className={"container "+arabicClass} id={"about-us"}>

            <div className={"row animated fast "+fadeInAnimation} id={"title-section"}>
                <div className={"col-lg-12 col-md-12 col-sm-12 "}>
                    <FormattedMessage tagName={"h1"} id={"aboutUs.title"}/>
                </div>
            </div>

            <div className={"row details-section animated fast "+fadeInAnimation}>
                <div className={"col-lg-12 col-md-12 col-sm-12 "}>
                    <FormattedMessage tagName={"h1"} id={"aboutUs.ourGoals.title"}/>
                </div>
                <div className={"col-lg-12 col-md-12 col-sm-12 section"}>
                    <FormattedMessage id="aboutUs.ourGoals.text">
                        {text=>
                            <span dangerouslySetInnerHTML={{__html: text}} />
                        }
                    </FormattedMessage>
                </div>
            </div>


            <div className={"row details-section animated fast "+fadeInAnimation}>
                <div className={"col-lg-12 col-md-12 col-sm-12 "}>
                    <FormattedMessage tagName={"h1"} id={"aboutUs.ourHistory.title"}/>
                </div>
                <div className={"col-lg-12 col-md-12 col-sm-12 section"}>
                    <FormattedMessage id="aboutUs.ourHistory.text">
                        {text=>
                            <span dangerouslySetInnerHTML={{__html: text}} />
                        }
                    </FormattedMessage>

                </div>
            </div>


            <div className={"row details-section animated fast "+fadeInAnimation}>
                <div className={"col-lg-12 col-md-12 col-sm-12 "}>
                    <FormattedMessage tagName={"h1"} id={"aboutUs.mission.title"}/>
                </div>
                <div className={"col-lg-12 col-md-12 col-sm-12 section"}>
                    <FormattedMessage id="aboutUs.mission.text">
                        {text=>
                            <span dangerouslySetInnerHTML={{__html: text}} />
                        }
                    </FormattedMessage>
                </div>
            </div>

            <div className={"row details-section animated fast "+fadeInAnimation}>
                <div className={"col-lg-12 col-md-12 col-sm-12 "}>
                    <FormattedMessage tagName={"h1"} id={"aboutUs.vision.title"}/>
                </div>
                <div className={"col-lg-12 col-md-12 col-sm-12 section"}>
                    <FormattedMessage id="aboutUs.vision.text">
                        {text=>
                            <span dangerouslySetInnerHTML={{__html: text}} />
                        }
                    </FormattedMessage>
                </div>
            </div>

        </div>

    )
}


export default connect(PageStates, PageDispatch)(About);
