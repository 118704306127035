import {
    CHANGE_THEME_COLOR,
    CHANGE_LANGUAGE
} from "../constants/ActionTypes";




export const change_theme = (payload = null) => {
    return {
        type: CHANGE_THEME_COLOR,
        payload
    };
};
export const change_language = (payload = null) => {

    return {
        type: CHANGE_LANGUAGE,
        payload
    };
};
