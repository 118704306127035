import React, {useEffect, useRef, useState} from "react";
import './home.scss';
import Images from '../../../../constants/Images';
// import {teal} from "@material-ui/core/colors";
import {useChain, useSpring, animated, useTransition} from "react-spring";
// import {Keyframes, animated as AnimatedComponent, config} from 'react-spring/renderprops'
// import delay from 'delay'
// import {MDBBox} from 'mdbreact';
import ReactPlayer from 'react-player'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


// import Swiper from 'react-id-swiper';
// import 'swiper/css/swiper.css'
// import "react-alice-carousel/lib/alice-carousel.css";
// import AliceCarousel from 'react-alice-carousel'
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {PageStates} from "../../../../reducers/connection/states";
import {PageDispatch} from "../../../../reducers/connection/functions";
import {_onClickLink} from "../../../../constants/Helper";
import {Input} from "antd";

const {
    images,
    karwnachi,
    brands,
} = Images.home;


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: {max: 4000, min: 3000},
        items: 10,
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 7,
    },
    tablet: {
        breakpoint: {max: 1024, min: 464},
        items: 2,
    },
    mobile: {
        breakpoint: {max: 464, min: 0},
        items: 1,
    },
};
const Home = ({match, isDirectionRTL, locale, route_change, history, select_category, categories, location, routeChanged, currentRoute}) => {

    const [leftAnimation, setLeftAnimation] = useState(isDirectionRTL ? "slideInRight" : "slideInLeft");
    const [rightAnimation, setRightAnimation] = useState(isDirectionRTL ? "slideInLeft" : "slideInRight");


    const [backgroundAnimation, setBackgroundAnimation] = useState("fadeIn");

    const duration = 1400;


    const onClickLink = (url, func = null) => {

        if (currentRoute === url || !url) return;
        route_change(url);

        setTimeout(() => {
            history.push(url);

            func && func();
        }, 750);

    }

    const productionRef = useRef();
    const productionProps = useSpring({
        ref: productionRef,
        config: {duration: 500},
        from: {transform: "translateY(200px)", opacity: 0},
        to: {transform: "translateY(0px)", opacity: 1}
    });

    const certificatesRef = useRef();
    const certificatesProps = useSpring({
        ref: certificatesRef,
        config: {duration: 500},
        from: {transform: "translateY(200px)", opacity: 0},
        to: {transform: "translateY(0px)", opacity: 1}
    });

    const image1Ref = useRef();
    const image2Ref = useRef();
    const image3Ref = useRef();
    const image4Ref = useRef();
    const image5Ref = useRef();
    const image6Ref = useRef();

    const imageProp = [
        useSpring({
            ref: image1Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1.2) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image2Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1.2) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image3Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1.2) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image4Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1.2) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image5Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1.2) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image6Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1.2) translateX(0px)", opacity: 1}
        }),
    ];

    const transRef = useRef();
    const transitions = useTransition(brands, item => item.image, {
        ref: transRef,
        unique: true,
        trail: 300 / brands.length,
        from: {opacity: 0, transform: 'scale(0)'},
        enter: {opacity: 1, transform: 'scale(1)'},
        leave: {opacity: 0, transform: 'scale(0)'}
    });


    // This will orchestrate the two animations above, comment the last arg and it creates a sequence
    useChain([image1Ref, image2Ref, image3Ref, image4Ref, image5Ref, image6Ref, productionRef, certificatesRef, transRef], [1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8]);


    const AnimFeTurbulence = animated('feTurbulence');
    const AnimFeDisplacementMap = animated('feDisplacementMap');


    const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

    const trans = [
        (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 20}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 30}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 40}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 50}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 60}px,${y / 10}px,0)`
    ];


    const params = {
        spaceBetween: 60,
        centeredSlides: true,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false
        },
        loop: true,
        // loopFillGroupWithBlank: true,
        slidesPerView: 'auto',
        // freeMode: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            1024: {
                slidesPerView: 4,
                spaceBetween: 40
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 30
            },
            640: {
                slidesPerView: 2,
                spaceBetween: 20
            },
            320: {
                slidesPerView: 1,
                spaceBetween: 10
            },
        },
        navigation: {
            hide: false
        }
    }

    useEffect(() => {
        if (routeChanged && currentRoute != match.path) {
            setLeftAnimation(isDirectionRTL ? "slideOutEasingRight" : "slideOutEasingLeft");
            setRightAnimation(isDirectionRTL ? "slideOutEasingLeft" : "slideOutEasingRight");
            setBackgroundAnimation("fadeOut");
        }
    }, [routeChanged]);

    const {detect} = require('detect-browser');
    const browser = detect();
    const arabicClass = isDirectionRTL ? "page-contents page-contents-rtl" : "page-contents page-contents-ltr";

    const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}))
    return (
        <div id={"home-page"} className={arabicClass}
             onMouseMove={({clientX: x, clientY: y}) => set({xy: calc(x, y)})}>
            <div className={"container-fluid"} style={{padding: 0}}>
                <animated.div style={{transform: props.xy.interpolate(trans[4])}}
                              className={"image-background animated " + backgroundAnimation}>
                    <img src={karwnachi}/>
                </animated.div>
                <div className={"row"} id={"first-section"}>

                    <div className={"col-lg-6 col-md-6 col-sm-12 section-1 animated " + leftAnimation}>

                        <div className={"slide-text"}>
                            <div className={"title animated fadeIn"}>
                                <FormattedMessage id="home.slider.karwanchigroup">
                                    {text =>
                                        <span dangerouslySetInnerHTML={{__html: text}}/>
                                    }
                                </FormattedMessage>
                            </div>
                            <div className={"desc animated fadeIn delay-1s"}>
                                <FormattedMessage tagName={"span"} id={"home.slider.text"}/>
                            </div>

                        </div>

                    </div>

                    <div
                        className={"col-lg-6 col-md-6 col-sm-12 section-2 animated " + rightAnimation + " browser_" + browser.name}>
                        <div className={"slide-images browser_" + browser.name}>
                            {
                                images.map((img, index) => {
                                    return (
                                        <animated.div style={imageProp[index]} className={"image-" + index}>
                                            <animated.img style={{transform: props.xy.interpolate(trans[index])}}
                                                          src={img}/>
                                        </animated.div>
                                    )
                                })
                            }

                        </div>
                    </div>
                </div>

                <div className={"row language-" + locale.locale} style={{alignItems: "flex-end"}} id={"second-section"}>
                    <div
                        className={"col-lg-6 col-md-5 col-sm-12 justify-content-lg-left section-item animated " + leftAnimation}>
                        <animated.div style={productionProps} id={"production-line-section"}>
                            <FormattedMessage tagName={"h1"} id={"home.productionLineTitle"}/>
                            <FormattedMessage id="home.productionLineShortDescription">
                                {text =>
                                    <span className={"language-" + locale.locale}
                                          dangerouslySetInnerHTML={{__html: text}}/>
                                }
                            </FormattedMessage>
                            <div className={"read-btn"}>
                                <FormattedMessage id="home.read">
                                    {text =>
                                        <a href={"#"} onClick={() => {
                                            onClickLink("/production")
                                        }} dangerouslySetInnerHTML={{__html: text}}/>

                                    }
                                </FormattedMessage>
                            </div>

                        </animated.div>
                    </div>
                    <div className={"col-lg-6 col-md-5 col-sm-12 section-item animated " + rightAnimation}>
                        <animated.div style={certificatesProps} id={"certificates-section"}>

                            <FormattedMessage tagName={"h1"} id={"home.certificateTitle"}/>

                            <FormattedMessage id="home.certificatesShortDescription">
                                {text =>
                                    <span className={"language-" + locale.locale}
                                          dangerouslySetInnerHTML={{__html: text}}/>
                                }
                            </FormattedMessage>
                            <div className={"read-btn"}>

                                <FormattedMessage id="home.read">
                                    {text =>
                                        <a href={"#"} onClick={() => {
                                            onClickLink("/certificates")
                                        }} dangerouslySetInnerHTML={{__html: text}}/>

                                    }
                                </FormattedMessage>

                            </div>
                        </animated.div>
                    </div>
                </div>


                <div className={"row" + rightAnimation} id={"third-section"}>
                    <div className={"col-lg-12 col-md-12 col-sm-12 section-item animated " + backgroundAnimation}>

                        <div className={"video-player"}>
                            <ReactPlayer url={Images.fireballvideo} loop muted playing width={"100%"} height={"auto"}
                                         style={{width: "100%", height: "100%"}}/>
                        </div>

                    </div>
                </div>

                <div className={"row" + leftAnimation} id={"forth-section"}>
                    <div className={"col-lg-12 col-md-12 col-sm-12 section-item animated " + leftAnimation}>
                        <FormattedMessage tagName={"span"} id={"home.brands.label"}/>
                        <FormattedMessage tagName={"h4"} id={"home.brands.title"}/>

                    </div>
                    <div className={"col-lg-12 col-md-12 col-sm-12 section-item animated " + rightAnimation}>


                        <Carousel

                            additionalTransfrom={0}
                            arrows
                            autoPlay
                            autoPlaySpeed={1}
                            centerMode={false}
                            className=""
                            containerClass="container-with-dots"
                            customTransition="all 2s linear"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={responsive}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                            transitionDuration={2000}
                            ssr={true} // means to render carousel on server-side.
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            deviceType={"tablet"}
                        >
                            {transitions.map(({item, key, props}) => (
                                <animated.div onClick={() => {

                                    const filterCategories = Array.isArray(categories) && categories.filter((cat) => {
                                        return cat.slug == item.link;
                                    });
                                    if (filterCategories.length > 0) {
                                        select_category(filterCategories[0]);
                                        _onClickLink("/category/" + item.link, route_change, currentRoute, history);
                                    }

                                }} className={"slider-image-item"} key={key} style={{...props}}>
                                    <img onDragStart={e => e.preventDefault()} src={item.image}/>
                                </animated.div>
                            ))}
                        </Carousel>

                        {/*
                        <AliceCarousel mouseTrackingEnabled>
                            {transitions.map(({item, key, props}) => (
                                <animated.div className={"slider-image-item"} key={key} style={{...props}}>
                                    <img src={item}/>
                                </animated.div>
                            ))}
                        </AliceCarousel>
*/}


                        {/*
                        <Swiper {...params}>
                            {transitions.map(({item, key, props}) => (
                                <animated.div className={"slider-image-item"} key={key} style={{...props}}>
                                    <img src={item}/>
                                </animated.div>
                            ))}
                        </Swiper>
*/}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default connect(PageStates, PageDispatch)(Home);
