export default {
  "menu.links.home": "لاپەڕەى ماڵ",
  "menu.links.products": "بەرهەم",
  "menu.links.stuff": "فريقنا",
  "menu.links.about": "دەربارەی ئێمە",
  "menu.links.contact": "پەیوەندی",
  "menu.links.production": "هێڵەکانی بەرهەمهێنان",
  "menu.links.certificates": "بروانامەکان",
  "menu.links.careers": "هه‌لی کار",
  "menu.links.legal": "معلومات قانونية",
  "menu.links.privacy": "نهێنیپارێزی",
  "footer.address": "العراق،كركوك ، التون كوبرى",

  "home.brands.label": "نفتخر بعلاماتنا التجارية",
  "home.brands.title": " ",

  "home.slider.karwanchigroup": "فريق كرونجي",
  "home.slider.text": "گروپی كۆمپانیاكانی كاروانچی چەندین جۆری خواردنەوەی گازی و شەربەت و ئاوی خواردنەوە وشیرەمەنی و خواردنەوەی وزەبەخش بەرهەم دەهێنێت بە هەموو قەبارە و شێوازه جۆراوجۆرەكان  وبەردەوام لە هەوڵی فراوانكردن و گەشەپێدانی بەرهەمی جیاوازتردایە تا رەزامەندیی بەكارهێنەرانی بەدەست بهێنێت",

  "home.read":"زياتر",

  "home.productionLineTitle" : "هێڵەکانی بەرهەمهێنان",
  "home.productionLineShortDescription": "تبلغ عدد الخطوط الحالية 15 خط إنتاجي، وسيتم إضافة 3 خطوط جديدة حسب التفاصيل المدرجة أدناه:\n" +
      "1 -خط قدح A :طاقته الإنتاجية 12600 قدح/ساعة\n" +
      "2 -خطقدحB:طاقته الإنتاجية 12600 قدح/ساعة\n" +
      "3 -خط قدح C:طاقته الإنتاجية 12600 قدح/ساعة",
  "home.productionLineLongDescription_1": "تبلغ عدد الخطوط الحالية 15 خط إنتاجي، وسيتم إضافة 3 خطوط جديدة حسب التفاصيل المدرجة أدناه:\n" +
      "1 -خط قدح A :طاقته الإنتاجية 12600 قدح/ساعة\n" +
      "2 -خطقدحB:طاقته الإنتاجية 12600 قدح/ساعة\n" +
      "3 -خط قدح C:طاقته الإنتاجية 12600 قدح/ساعة\n" +
      "4 -خطقدحD:طاقته الإنتاجية 12600 قدح/ساعة\n" +
      "5 -خط زجاجي A :طاقته الإنتاجية 24000زجاجة/ساعة\n" +
      "6 -خط زجاجي B :طاقته الإنتاجية 24000زجاجة/ساعة\n" +
      "7 -خطPET رقم 1:طاقتهالإنتاجية13000 عبوة بلاستيكية/ساعة\n" +
      "8 -خطPET رقم 2:طاقته الإنتاجية 24000 عبوة بلاستيكية/ساعة\n" +
      "9 -خطPET رقم 3:طاقته الإنتاجية 24000 عبوة بلاستيكية/ساعة\n" +
      "10 -خط علبة معدنية KHS :طاقته الإنتاجية 72000 علبة/ساعة\n" +
      "11 -خط SIG- Combiblocرقم1 :طاقته الإنتاجية 24000 علبة كرتونية/ساعة\n" +
      "12 -خطSIG- Combiblocرقم2:طاقته الإنتاجية 24000 علبة كرتونية/ساعة\n",
  "home.productionLineLongDescription_2":"13 -خطSIG- Combiblocرقم3 :طاقته الإنتاجية 24000 علبة كرتونية/ساعة\n" +
      "14 -خط TETRAPAKرقم1:طاقته الإنتاجية 24000 علبة كرتونية/ساعة\n" +
      "15 -خط TETRAPAKرقم 2 :طاقته الإنتاجية 24000 علبة كرتونية/ساعة\n" +
      "16 -خط PETنوع KRONES من Z-A :لتعبئة العصò بطاقة إنتاجية 36000 عبوة بلاستيكية/ساعة، سيتم تشغيله 1-1-2019\n" +
      "17 -خط علب معدنية نوعKRONES من Z-A : ُ لتعبئة العصò» حبيبات»، بطاقة إنتاجية 48000 علبة/ساعة،سيتم تشغيله 1-1-2019\n" +
      "18 -خط زجاجي نوع KRONESمنZ-A :لتعبئة العصائر والمشروبات الغازية، بطاقة إنتاجية 55000 زجاجة/ساعة،سيتم تشغيله 1-9-2019\n" +
      "نسبة تشغيل المكائن من مجمل الطاقة الإنتاجية 80٪",


  "home.certificateTitle": "بروانامەکان",
  "home.certificatesShortDescription": "الشركة حاصلة على إجازة تأسيس من المديرية العامة للتنمية الصناعية العراقية ذات رقم 81387، وحاصلة أيضاً على إجازة صحية المرقّمة 36 بالإضافة إلى شهادات الآيزو 2008:9001 & 2005:22000 وشهادة حلال.",
  "home.certificatesLongDescription": "الشركة حاصلة على إجازة تأسيس من المديرية العامة للتنمية الصناعية العراقية ذات رقم 81387، وحاصلة أيضاً على إجازة صحية المرقّمة 36 بالإضافة إلى شهادات الآيزو 2008:9001 & 2005:22000 وشهادة حلال.",

  "aboutUs.title": "دەربارەی ئێمە",

  "aboutUs.mission.title": "ئەركمان",
  "aboutUs.mission.text": "كاروانچی بەردەوام لە هەوڵی ئەوەدایە كە لە بەرهەمە سروشتییەكانیدا داهێنانی زیاتر بكات لەڕێی  بەكارهێنانی نوێترین تەكنەلۆجیا و باشترین هێڵی بەرهەمهێنان تا لە ئاست خواستی بەكارهێنەرانیدا بێت، وبەرهەمەكانى بەپێی ستانداردی جیهانیی و بەگونجاوترین نرخ بخاتە بەردەست سەرجەم بەكارهێنەران لە سەرتاسەری عێراق.",
  "aboutUs.vision.title": "خەونمان",
  "aboutUs.vision.text": "كۆمپانیای كاروانچی كار لەسەر ئەوە دەكات كە ببێتە پێشەنگی بازاڕە ناوخۆیی و دەرەكییەكان لە پێشكەشكردنی بەرهەمی نایاب و ناوازەدا، و بەرهەمەكانی 100% سروشتی بن و ببنە بەشێك لە ژیانی تەندروستی بەكارهێنەران.",

  "aboutUs.ourGoals.title": "ئامانجمان",
  "aboutUs.ourGoals.text": "گروپی کۆمپانیاکانی کرونجی بەردەوام لە گەشەکردن و داهێنانە وە بەردەوام هەوڵ دەدات داهێنانی نوێ بەدەست بهێنێت و کار دەکات بۆ ئەوەی ببێتە نوێنەر لە بواری فرۆشتن بە کۆمەڵ و تاک، هەروەها بەڵێنتان پێدەدات کە بەردەوام کار بکات بۆ پێشکەشکردنی باشترین خزمەتگوزاری و بەرهەمهێنانی کاڵای نوێ بە ستاندەردی جیهانی.",
  "aboutUs.ourHistory.title": "مێژووی",
  "aboutUs.ourHistory.text": "ئێستا گروپی كۆمپانیاكانی كاروانچی یەكێكە لە كۆمپانیا گەورەكانی بواری پیشەسازیی لە عێراق،  وخاوەنی پێگەیەكی جەماوەریی فراوانە لە بەكارهێنەرانی بەرهەمەكانی و توانیویەتی ببێتە پێشەنگی بازاڕەكانی عێراق بەهۆی كوالیتی وجۆری نایابی بەرهەمەكانیيەوە. <br />" +
      "سەرەتا گروپی كاروانچی ساڵی 1999 لە كەركوك بە ناوی كۆمپانیای \"التأمیم\" بۆ خواردنەوە گازییەكان دامەزرا، لەسەر ڕوبەریی 11,500 مەتر چوارگۆشە، بە دوو هێڵی بەرهەمهێنانی بوتڵی شوشە و خواردنەوە گازییەكان و سێ هێڵی بەرهەمهێنانی PET، دواتر لە ساڵی2001 كۆمپانیای \"مینا\"ی دامەزراند بۆ بەرهەمهێنانی ئاوی خواردنەوە لەسەر ڕوبەریی 20,000 مەتر چوارگۆشە وبە 6 هێڵی بەرهەمهێنانی پەرداخی پلاستیكی و 3 هێڵی بەرهەمهێنانی PET و 3 هێڵی بەرهەمهێنانی دەبەی ئاوی 18 لیتریی له ساڵی 2010 بوو بە  كۆمپانیای كاروانچی بۆ خواردنەوە گازییەكان و ئاوی خوادنەوە و بەرهەمهێنانی شەربەت و شیرەمەنی سنووردار لەسەر ڕوبەری 160,000 مەتر چوارگۆشە. <br />" +
      "گروپی كۆمپانیاكانی كاروانچی هەمیشە لە هەوڵی ئەوەدایە كە باشترین بەرهەم پێشكەش بە كڕیاران بكات، لەبەر ئەوە  بەپێی ستانداردە جیهانییەكان كار دەكات وتوانیویەتی بڕوانامەی ئایزۆی جیهانیی بەدەستبهێنێت بۆ پێوانەی سەلامەتی خۆراك. و هەروەها بە سیستمێكی كارگێڕیی بەتوانا و شارەزا كار دەكات ولەو بوارەشدا بڕوانامەی ئایزۆی تایبەت بە سیستمی كارگێڕیی ئاست بەرزی پێ بەخشراوە، لە پاڵ بڕوانامەی حەلآڵیی بەرهەمەكانی.<br />",

  "privacy.text":"مجموعة شركات الكرونجي والشركات التابعة تتعهد على ان لا يتم بيع, استئجار او استخدام اي بيانات شخصية ( المشار اليها في ما يلي بالعبارة” المعلومات<br />" +
      "الشخصية”) كالأسم, عنوان الوظيفة الألكترونية وعناوين الوظيفة والسكن وارقام الهواتف التي كتبت على الاستãرات لغرض الزيارة الى اي شخص ثالث.<br />" +
      "مجموعة شركات الكرونجي والشركات التابعة تستخدم المعلومات الشخصية بشكل محدود بقدر الامكان لأسباب الواردة: ارسال منشورات صحفية ومحاورات,<br />" +
      "ارسال نشرة صحفية من قبل المشاركات الالكترونية او شراء المواد او تسليم المكافئات.<br />" +
      "المعلومات الشخصية متاحة فقط للمخول· من مجموعة شركات الكرونجي والشركات التابعة والطرف الثالث الذي يتعهد على الحفاظ على سرية المعلومات.<br />" +
      "مجموعة شركات الكرونجي والشركات التابعة تحافظ على خصوصية البيانات والمعلومات الشخصية من الاستنساخ او النشر بأي طريقة, ما اذا كان عن طريق<br />" +
      "(الماسح الضوì ,نظام تحديد المواقع العالمية, بيانات رقمية, معلومات عن الجنس والعمر, الخ.).<br />" +
      "مجموعة شركات الكرونجي والشركات التابعة لها الصلاحية في استخدام بعض المعلومات والبيانات الشخصية (كأسم المستخدم في صفحات الاجتãعية وصورة<br />" +
      "الحساب الشخصي) في هذه الصفحة الالكترونية لغرض الترويج والتطوير. جميع الحقوق التي ذكرة يحتفظ بها مجموعة شركات الكرونجي والشركات التابعة.<br />" +
      "في<br />" +
      "مجموعة شركات الكرونجي والشركات التابعة لا تحمل المسؤولية بشأن اي مشاكل او خلافات او سرية المعلومات الشخصية ب· المستخدم لهذه الصفحة وب·<br />" +
      "اي رابط لصفحة الكترونية من خلال هذه الصفحة. لهذه نحن ننصح بأتخاذ اي تدابò لازمة للحفاظ على سرية المعلومات الشخصية للزائر او المستخدم قبل<br />" +
      "الولوج الى اي صفحة الكترونية من خلال رابط متواجد في هذه الصفحة وادخال معلومات التي تعتبر خاصة بالمتصفح.",


  "category.view_products": "بەرهەم ببينە",
  "category.back_products": "دواوە",

  "contacts.address": "ناوونيشان",
  "contacts.phones": "تەلەفۆن",
  "contacts.emails": "ئيمێل",

  "careers.title": "هه‌لی کار",
  "careers.apply": "داوا بكە بۆ كار",
  "careers.form.fullname": "ناوى تەواو",
  "careers.form.email": "ئيمێل",
  "careers.form.phone": "ژمارە تەلەفۆن بكە",
  "careers.form.jobtitle": "ناونيشانى كار",
  "careers.form.location": "شوێنى شوێنى نيشتەجێبوون",
  "careers.form.uploadcv": "سيڤيت ئەپلۆد بكە",
  "careers.form.send": "جێبەجێ دەكات",

  "careers.jobs.jobTitle": "ناونيشانى كار",
  "careers.jobs.jobDescription": "باسكردنى كار",
  "careers.jobs.jobSalary": "مووچە",
  "careers.jobs.jobLocation": "شوێن",

  "careers.unspecified": "ئەنسپێسەفايد",
  "careers.success.title": "دەكات",
  "careers.success.message": "نامە نێررابوو",

  "contactus.title": "پەیوەندی",
  "contactus.form.message": "نامە",
  "contactus.form.send": "دەنێرێت"
}
