import logo from '../assets/images/logo.svg';
import redLogo from '../assets/images/redLogo.svg';
import facebook from '../assets/images/social/facebook.svg';
import instagram from '../assets/images/social/instagram.svg';
import linkedin from '../assets/images/social/linkedin.svg';
import youtube from '../assets/images/social/youtube.svg';
import twitter from '../assets/images/social/twitter.svg';
import menuBtn from '../assets/images/menu_btn.png';


import image_1 from '../assets/images/home/images/tazech.png';
import image_2 from '../assets/images/home/images/reach.png';
import image_3 from '../assets/images/home/images/fireball.png';
import image_4 from '../assets/images/home/images/kcola.png';
import image_5 from '../assets/images/home/images/nogou.png';
import image_6 from '../assets/images/home/images/zbeeb.png';


import certificate_1 from '../assets/images/certificates/Picture1.png';
import certificate_2 from '../assets/images/certificates/Picture2.png';
import certificate_3 from '../assets/images/certificates/Picture3.png';

import karwnachi from '../assets/images/home/karwnachi.png';
import about_bg from '../assets/images/about_bg.png';
import production_bg from '../assets/images/production_bg.png';
import karwanchi_logo from '../assets/images/karwanchi_logo@3x.png';


import bubbles from '../assets/images/categories/bubbles.png';
import bubble_image_1 from '../assets/images/categories/bubbles/image_1.png';
import bubble_image_2 from '../assets/images/categories/bubbles/image_2.png';
import bubble_image_3 from '../assets/images/categories/bubbles/image_3.png';
import bubble_image_4 from '../assets/images/categories/bubbles/image_4.png';
import bubble_image_5 from '../assets/images/categories/bubbles/image_5.png';
import bubble_image_6 from '../assets/images/categories/bubbles/image_6.png';
import bubble_image_8 from '../assets/images/categories/bubbles/image_8.png';



import category_1_tazech_juice_backgroundImage from '../assets/images/categories/category_1_tazech_juice/backgroundImage.png';
import category_1_tazech_juice_image_1 from '../assets/images/categories/category_1_tazech_juice/image_1.png';
import category_1_tazech_juice_image_2 from '../assets/images/categories/category_1_tazech_juice/image_2.png';
import category_1_tazech_juice_image_3 from '../assets/images/categories/category_1_tazech_juice/image_3.png';
import category_1_tazech_juice_image_4 from '../assets/images/categories/category_1_tazech_juice/image_4.png';
import category_1_tazech_juice_image_5 from '../assets/images/categories/category_1_tazech_juice/image_5.png';
import category_1_tazech_juice_image_6 from '../assets/images/categories/category_1_tazech_juice/image_6.png';
import category_1_tazech_juice_bg1 from '../assets/images/categories/category_1_tazech_juice/bg1.png';
import category_1_tazech_juice_bg2 from '../assets/images/categories/category_1_tazech_juice/bg2.png';
import category_1_tazech_juice_corner_1 from '../assets/images/categories/category_1_tazech_juice/corner1.png';

import category_2_fire_ball_backgroundImage from '../assets/images/categories/category_2_fire_ball/backgroundImage.png';
import category_2_fire_ball_juice_gif_1 from '../assets/images/categories/category_2_fire_ball/fire-ball-black.gif';


import category_3_karwnchi_water_backgroundImage from '../assets/images/categories/category_3_karwnchi_water/backgroundImage.png';
import category_3_karwnchi_water_image_1 from '../assets/images/categories/category_3_karwnchi_water/image_1.png';
import category_3_karwnchi_water_image_2 from '../assets/images/categories/category_3_karwnchi_water/image_2.png';
import category_3_karwnchi_water_image_3 from '../assets/images/categories/category_3_karwnchi_water/image_3.png';
import category_3_karwnchi_water_bubble_1 from '../assets/images/categories/category_3_karwnchi_water/drops/1.svg';
import category_3_karwnchi_water_bubble_2 from '../assets/images/categories/category_3_karwnchi_water/drops/2.svg';
import category_3_karwnchi_water_bubble_3 from '../assets/images/categories/category_3_karwnchi_water/drops/3.svg';
import category_3_karwnchi_water_line from '../assets/images/categories/category_3_karwnchi_water/line.png';


import category_4_nakoo_backgroundImage from '../assets/images/categories/category_4_nakoo/backgroundImage.png';
import category_4_nakoo_image_1 from '../assets/images/categories/category_4_nakoo/image_1.png';
import category_4_nakoo_image_2 from '../assets/images/categories/category_4_nakoo/image_2.png';
import category_4_nakoo_image_3 from '../assets/images/categories/category_4_nakoo/image_3.png';


import category_5_valencia_backgroundImage from '../assets/images/categories/category_5_valencia/backgroundImage.png';
import category_5_valencia_image_1 from '../assets/images/categories/category_5_valencia/image_1.png';
import category_5_valencia_image_2 from '../assets/images/categories/category_5_valencia/image_2.png';
import category_5_valencia_image_3 from '../assets/images/categories/category_5_valencia/image_3.png';
import category_5_valencia_image_4 from '../assets/images/categories/category_5_valencia/image_4.png';
import category_5_valencia_image_5 from '../assets/images/categories/category_5_valencia/image_5.png';
import category_5_valencia_grain_1 from '../assets/images/categories/category_5_valencia/grains/1.svg';
import category_5_valencia_ar_logo from '../assets/images/categories/category_5_valencia/ar-logo.png';

import category_6_cola_backgroundImage from '../assets/images/categories/category_6_cola/backgroundImage.png';
import category_6_cola_image_1 from '../assets/images/categories/category_6_cola/image_1.png';
import category_6_cola_image_2 from '../assets/images/categories/category_6_cola/image_2.png';
import category_6_cola_image_3 from '../assets/images/categories/category_6_cola/image_3.png';

import category_7_fri_dai_friday_backgroundImage from '../assets/images/categories/category_7_fri_dai_friday/backgroundImage.png';
import category_7_fri_dai_friday_image_1 from '../assets/images/categories/category_7_fri_dai_friday/image_1.png';
import category_7_fri_dai_friday_image_2 from '../assets/images/categories/category_7_fri_dai_friday/image_2.png';
import category_7_fri_dai_friday_image_3 from '../assets/images/categories/category_7_fri_dai_friday/image_3.png';
import category_7_fri_dai_friday_image_4 from '../assets/images/categories/category_7_fri_dai_friday/image_4.png';
import category_7_fri_dai_friday_image_5 from '../assets/images/categories/category_7_fri_dai_friday/image_5.png';

import category_8_malt_backgroundImage from '../assets/images/categories/category_8_malt/backgroundImage.png';
import category_8_malt_image_1 from '../assets/images/categories/category_8_malt/image_1.png';
import category_8_malt_image_2 from '../assets/images/categories/category_8_malt/image_2.png';
import category_8_malt_image_3 from '../assets/images/categories/category_8_malt/image_3.png';
import category_8_malt_image_4 from '../assets/images/categories/category_8_malt/image_4.png';

import category_9_rich_backgroundImage from '../assets/images/categories/category_9_rich/imageBackground.png';
import category_9_rich_image_1 from '../assets/images/categories/category_9_rich/image_1.png';
import category_9_rich_image_2 from '../assets/images/categories/category_9_rich/image_2.png';
import category_9_rich_image_3 from '../assets/images/categories/category_9_rich/image_3.png';
import category_9_rich_image_4 from '../assets/images/categories/category_9_rich/image_4.png';

import category_10_zbeeb_backgroundImage from '../assets/images/categories/category_10_zbeeb/imageBackground.png';
import category_10_zbeeb_image_1 from '../assets/images/categories/category_10_zbeeb/image_1.png';
import category_10_zbeeb_image_2 from '../assets/images/categories/category_10_zbeeb/image_2.png';
import category_10_zbeeb_grap_1 from '../assets/images/categories/category_10_zbeeb/graps/1.png';
import category_10_zbeeb_grap_2 from '../assets/images/categories/category_10_zbeeb/graps/2.png';
import category_10_zbeeb_grap_3 from '../assets/images/categories/category_10_zbeeb/graps/3.png';
import category_10_zbeeb_grap_4 from '../assets/images/categories/category_10_zbeeb/graps/4.png';
import category_10_zbeeb_leaf_1 from '../assets/images/categories/category_10_zbeeb/leafs/image_1.png';
import category_10_zbeeb_leaf_2 from '../assets/images/categories/category_10_zbeeb/leafs/image_2.png';


import category_11_natural_water_backgroundImage from '../assets/images/categories/category_11_natural_water/imagebackground.png';
import category_11_natural_water_image_1 from '../assets/images/categories/category_11_natural_water/image_1.png';
import category_11_natural_water_image_2 from '../assets/images/categories/category_11_natural_water/image_2.png';
import category_11_natural_water_ice_image_1 from '../assets/images/categories/category_11_natural_water/ice/ani1.png';
import category_11_natural_water_ice_image_2 from '../assets/images/categories/category_11_natural_water/ice/ani2.png';

import category_12_mina_water_backgroundImage from '../assets/images/categories/category_12_mina_water/imageBackground.png';
import category_12_mina_water_image_1 from '../assets/images/categories/category_12_mina_water/image_1.png';
import category_12_mina_water_image_2 from '../assets/images/categories/category_12_mina_water/image_2.png';
import category_12_mina_water_image_3 from '../assets/images/categories/category_12_mina_water/image_3.png';
import category_12_mina_water_image_4 from '../assets/images/categories/category_12_mina_water/image_4.png';

import category_13_tonic_backgroundImage from '../assets/images/categories/category_13_tonic/imageBackground.png';
import category_13_tonic_image_1 from '../assets/images/categories/category_13_tonic/image_1.png';
import category_13_tonic_image_2 from '../assets/images/categories/category_13_tonic/image_2.png';
import category_13_tonic_image_3 from '../assets/images/categories/category_13_tonic/image_3.png';
import category_13_tonic_leaf_1 from '../assets/images/categories/category_13_tonic/leaves/image_1.png';
import category_13_tonic_leaf_2 from '../assets/images/categories/category_13_tonic/leaves/image_2.png';



import brand1 from '../assets/images/home/brands/Brand-01.svg';
import brand2 from '../assets/images/home/brands/Brand-02.svg';
import brand3 from '../assets/images/home/brands/Brand-03.svg';
import brand4 from '../assets/images/home/brands/Brand-04.svg';
import brand5 from '../assets/images/home/brands/Brand-05.svg';
import brand6 from '../assets/images/home/brands/Brand-06.svg';
import brand7 from '../assets/images/home/brands/Brand-07.svg';
import brand8 from '../assets/images/home/brands/Brand-08.svg';
import brand9 from '../assets/images/home/brands/Brand-09.svg';
import brand10 from '../assets/images/home/brands/Brand-11.svg';
import brand11 from '../assets/images/home/brands/Brand-12.svg';
import brand12 from '../assets/images/home/brands/Brand-13.svg';
import brand13 from '../assets/images/home/brands/Brand-14.svg';
import brand14 from '../assets/images/home/brands/Brand-15.svg';
import fireballvideo from '../assets/fireballvideo.mp4';


export default {
    logo,
    redLogo,
    facebook,
    instagram,
    linkedin,
    youtube,
    twitter,
    menuBtn,
    bubbles,
    about_bg,
    production_bg,
    karwanchi_logo,
    category_3_karwnchi_water_line,

    certificates:[
        certificate_1,
        certificate_2,
        certificate_3
    ],

    home:{
        images:[
            image_3,
            image_5,
            image_6,
            image_2,
            image_1,
            image_4,
        ],

        karwnachi,


        brands:[
            {
                image:brand1,
                link:"fire_ball"
            },
            {
                image:brand2,
                link:"tazech_juice"
            },
            {
                image:brand3,
                link:"k_cola"
            },
            {
                image:brand4,
                link:""
            },
            {
                image:brand5,
                link:"valencia"
            },
            {
                image:brand6,
                link:"malt"
            },
            {
                image:brand7,
                link:""
            },
            {
                image:brand8,
                link:"karwnchi_water"
            },
            {
                image:brand9,
                link:"natural_water"
            },
            {
                image:brand10,
                link:"rich"
            },
            {
                image:brand11,
                link:""
            },
            {
                image:brand12,
                link:"friday"
            },
            {
                image:brand13,
                link:"ngoue"
            },
            {
                image:brand14,
                link:"zbeeb"
            },
        ],
        brand1,
        brand2,
        brand3,
        brand4,
        brand5,
        brand6,
        brand7,
        brand8,
        brand9,
        brand10,
        brand11,
        brand12,
        brand13,
        brand14,
    },

    categories:{
        "tazech_juice":{
            background:category_1_tazech_juice_backgroundImage,
            images:[
                category_1_tazech_juice_image_1,
                category_1_tazech_juice_image_2,
                category_1_tazech_juice_image_3,
                category_1_tazech_juice_image_4,
                category_1_tazech_juice_image_5,
                category_1_tazech_juice_image_6
            ],
            cat1:[
                category_1_tazech_juice_bg1
            ],
            cat2:[
                category_1_tazech_juice_bg2
            ],
            corners:[
               {
                   img:category_1_tazech_juice_corner_1,
                   corner:"left"
               }
            ],
            motion:[]
        },
        "fire_ball":{
            background:category_2_fire_ball_backgroundImage,
            images:[
                // category_2_fire_ball_juice_gif_1
            ],
            gif:[
                category_2_fire_ball_juice_gif_1,
            ],
            motion:[]
        },
        "karwnchi_water":{
            background:category_3_karwnchi_water_backgroundImage,
            images:[
                category_3_karwnchi_water_image_1,
                category_3_karwnchi_water_image_2,
                category_3_karwnchi_water_image_3,
            ],
            motion:[
                category_3_karwnchi_water_bubble_1,
                category_3_karwnchi_water_bubble_2,
                category_3_karwnchi_water_bubble_3
            ]
        },
        "ngoue":{
            background:category_4_nakoo_backgroundImage,
            images:[
                category_4_nakoo_image_1,
                category_4_nakoo_image_2,
                category_4_nakoo_image_3,
            ],
            motion:[
                category_3_karwnchi_water_bubble_1,
                category_3_karwnchi_water_bubble_2,
                category_3_karwnchi_water_bubble_3
            ]
        },
        "valencia":{
            background:category_5_valencia_backgroundImage,
            logo:{
              ar: category_5_valencia_ar_logo,
              kr: category_5_valencia_ar_logo,
            },
            images:[
                category_5_valencia_image_1,
                category_5_valencia_image_2,
                category_5_valencia_image_3,
                category_5_valencia_image_4,
                category_5_valencia_image_5
            ],
            motion:[
                category_5_valencia_grain_1,
            ]
        },
        "k_cola":{
            background:category_6_cola_backgroundImage,
            images:[
                category_6_cola_image_1,
                category_6_cola_image_2,
                category_6_cola_image_3,
            ],
            motion:[
                bubble_image_1,
                bubble_image_2,
                bubble_image_3,
                bubble_image_4,
                bubble_image_5,
                bubble_image_6,
                bubble_image_8,
            ]
        },
        "friday":{
            background:category_7_fri_dai_friday_backgroundImage,
            images:[
                category_7_fri_dai_friday_image_1,
                category_7_fri_dai_friday_image_2,
                category_7_fri_dai_friday_image_3,
                category_7_fri_dai_friday_image_4,
                category_7_fri_dai_friday_image_5

            ],
            motion:[
                category_5_valencia_grain_1,
            ]

        },
        "malt":{
            background:category_8_malt_backgroundImage,
            images:[
                category_8_malt_image_1,
                category_8_malt_image_2,
                category_8_malt_image_3,
                category_8_malt_image_4
            ],
            motion:[
                bubble_image_1,
                bubble_image_2,
                bubble_image_3,
                bubble_image_4,
                bubble_image_5,
                bubble_image_6,
                bubble_image_8,
            ]
        },
        "rich":{
            background:category_9_rich_backgroundImage,
            images:[
                category_9_rich_image_1,
                category_9_rich_image_2,
                category_9_rich_image_3,
                category_9_rich_image_4,
            ]
        },
        "zbeeb":{
            background:category_10_zbeeb_backgroundImage,
            images:[
                category_10_zbeeb_image_1,
                category_10_zbeeb_image_2,
            ],
            motion:[
                category_10_zbeeb_grap_1,
                category_10_zbeeb_grap_2,
                category_10_zbeeb_grap_3,
                category_10_zbeeb_grap_4
            ],
            swing:[
                category_10_zbeeb_leaf_1,
                category_10_zbeeb_leaf_2
            ]
        },
        "natural_water":{
            background:category_11_natural_water_backgroundImage,
            images:[
                category_11_natural_water_image_1,
                category_11_natural_water_image_2,
            ],
            motion:[
                category_3_karwnchi_water_bubble_1,
                category_3_karwnchi_water_bubble_2,
                category_3_karwnchi_water_bubble_3
            ],
            ice1:[
                category_11_natural_water_ice_image_1
            ],
            ice2:[
                category_11_natural_water_ice_image_2
            ]
        },
        "mina":{
            background:category_12_mina_water_backgroundImage,
            images:[
                category_12_mina_water_image_1,
                category_12_mina_water_image_2,
                category_12_mina_water_image_3,
                category_12_mina_water_image_4,
            ],
            motion:[
                category_3_karwnchi_water_bubble_1,
                category_3_karwnchi_water_bubble_2,
                category_3_karwnchi_water_bubble_3
            ]
        },
        "tonic":{
            background:category_13_tonic_backgroundImage,
            images:[
                category_13_tonic_image_1,
                category_13_tonic_image_2,
                category_13_tonic_image_3,
            ],
            motion:[
                bubble_image_1,
                bubble_image_2,
                bubble_image_3,
                bubble_image_4,
                bubble_image_5,
                bubble_image_6,
                bubble_image_8,
            ],
            swing:[
                category_13_tonic_leaf_1,
                category_13_tonic_leaf_2
            ]
        },
    },

    fireballvideo
}
