import appLocaleData from 'react-intl/locale-data/tr';
import trMessages from '../locales/tr_TR';

const TrLang = {
    messages: {
        ...trMessages
    },
    rtl:false,
    label: "Türkçe",
    locale: 'tr',
    data: appLocaleData
};
export default TrLang;
