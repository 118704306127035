import {consoleLog, validatingJSON} from "../constants/Helper";
import {
    ADDED_SOMETHING,
    FETCH_NEWSLETTERS,
    FETCH_CATEGORIES,
    FETCH_PRODUCTS,
    FETCH_SETTINGS,
    CHANGE_DISPLAY_MODE,
    FETCH_CAREERS,
    SELECT_CATEGORY,
    SELECT_PRODUCT,
} from "../constants/ActionTypes";


const getFromStorage = (key) => {
    const selectedKey = localStorage.getItem(key);
    return validatingJSON(selectedKey) && JSON.parse(selectedKey) ? JSON.parse(selectedKey) : null;
}

const selectedCategoryState = getFromStorage("selected_category");
const categoriesListState = getFromStorage("categories_list");
const productsListState = getFromStorage("products_list");
const settingsListState = getFromStorage("settings");
const newsLettersListState = getFromStorage("newsletters_list");
const careersListState = getFromStorage("careers_list");

const INIT_STATE = {
    loader: false,
    selected_category: selectedCategoryState,
    selected_product: null,
    categories_list: categoriesListState,
    products_list: productsListState,
    viewCategories:false,
    newsletters_list: newsLettersListState,
    careers_list: careersListState,
    loading: false,
    somethingChanged: null,
    configuration: settingsListState

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_CATEGORIES: {
            let payload = action.payload;
            localStorage.setItem("categories_list", JSON.stringify(payload));
            return {
                ...state,
                loader: false,
                categories_list: payload
            }
        }
        case FETCH_SETTINGS: {

            let payload = action.payload.data;
            localStorage.setItem("settings", JSON.stringify(payload));
            return {
                ...state,
                loader: false,
                settings: payload

            }
        }
        case FETCH_PRODUCTS: {
            let payload = action.payload.data;
            localStorage.setItem("products_list", JSON.stringify(payload));

            return {
                ...state,
                loader: false,
                products_list: payload

            }
        }
        case FETCH_NEWSLETTERS: {
            let payload = action.payload.data;
            localStorage.setItem("newsletters_list", JSON.stringify(payload));

            return {
                ...state,
                loader: false,
                newsletters_list: payload

            }
        }
        case FETCH_CAREERS: {
            let payload = action.payload;
            localStorage.setItem("careers_list", JSON.stringify(payload));

            return {
                ...state,
                loader: false,
                careers_list: payload
            }
        }
        case SELECT_CATEGORY: {
            let payload = action.payload;
            localStorage.setItem("selected_category", JSON.stringify(payload));


            return {
                ...state,
                loader: false,
                selected_category: action.payload
            }
        }
        case SELECT_PRODUCT: {
            return {
                ...state,
                loader: false,
                selected_product: action.payload
            }
        }
        case CHANGE_DISPLAY_MODE: {
            return {
                ...state,
                viewCategories: action.payload
            }
        }
        case ADDED_SOMETHING: {
            return {
                ...state,
                somethingChanged: new Date().getTime()

            }
        }
        default:
            return state;
    }
}
