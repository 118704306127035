import React, {useEffect, useState} from 'react';
import './pageBackground.scss';
import {connect} from "react-redux";
import {FooterStates, PageStates} from "../../../reducers/connection/states";
import {FooterDispatch, PageDispatch} from "../../../reducers/connection/functions";
import Bubbles from "../../Bubbles";
import Images from "../../../constants/Images";
import ProductInfo from "../../ProductInfo";


const PageBackground = ({children, routeChanged, location,viewCategories, locale, selectedProduct, selectedCategory, isDirectionRTL, match}) => {

    const [cssClass,setCssClass] = useState("fadeInRight");
    const catName = location.pathname.split("/")[1];

    const isCategory = catName === "category";
    const categoryPage = catName == "category" && selectedCategory ? selectedCategory.slug : "default";







    useEffect(()=>{
        // setLeftAnimation(isDirectionRTL ? "slideInRight delay-0.5s" : "slideInLeft delay-0.5s");
        // setRightAnimation(isDirectionRTL ? "slideInLeft delay-0.5s" : "slideInRight delay-0.5s");

        // setLeftAnimation(isDirectionRTL ? "slideOutEasingRight" : "slideOutEasingLeft");
        // setRightAnimation(isDirectionRTL ? "slideOutEasingLeft" : "slideOutEasingRight");


        const ltr = categoryPage !== "fire_ball"?"slideInRight delay-0.5s":"slideOutEasingRight";
        const rtl = categoryPage !== "fire_ball"?"slideInLeft delay-0.5s":"slideOutEasingLeft";

        const cssEnter =  isDirectionRTL?rtl:ltr;

        setCssClass(cssEnter);
    },[routeChanged]);

    const {detect} = require('detect-browser');
    const browser = detect();
    return (
        <div className={`app-${categoryPage}`} dir={isDirectionRTL ? "rtl" : "ltr"} id={"app"}>
            <Bubbles location={location}/>

            {!viewCategories && categoryPage && categoryPage == "fire_ball" && <div className={"gif-background animated "+cssClass}>
                <img className={isDirectionRTL ? "gif-background-rtl" : "gif-background-ltr"} src={Images.categories.fire_ball.gif[0]}/>
            </div>}


            {catName == "about" && <div className={"image_background"}>
                <img src={Images.about_bg}/>
            </div>}
            {(catName == "policy" || catName == "production") && <div className={"image_background"}>
                <img src={Images.production_bg}/>
            </div>}
            {(catName == "policy" || catName == "production" || catName == "about") &&
            <div className={"image_background image_background_karwnchi"}>
                <img src={Images.karwanchi_logo}/>
            </div>}



            <div id={"page-container"} className={" browser_" + browser.name} style={{zIndex: 9999}}>
                {children}
            </div>


            {isCategory &&
            <ProductInfo />}

        </div>

    )
}


export default connect(PageStates, PageDispatch)(PageBackground);
