import React, {useEffect, useState} from 'react';
import './careers.scss';
import {connect} from "react-redux";
import {FooterStates, PageStates} from "../../../../reducers/connection/states";
import {FooterDispatch, PageDispatch} from "../../../../reducers/connection/functions";
import {injectIntl,FormattedMessage} from 'react-intl';
import {Input} from 'antd'
import {Button, Radio, Icon} from 'antd';
import swal from 'sweetalert';
import axios from 'axios';
import APIs from '../../../../constants/ApiURL';
import 'antd/dist/antd.css'
import IntlMessages from "../../../../util/IntlMessages";

const Careers = ({match, routeChanged, isDirectionRTL, currentRoute, careersList,locale, children, change_theme, themeColor, themeFooterBackgroundGradient, themeFooterLineGradient, themePageBackgroundGradient}) => {

    const [leftAnimation,setLeftAnimation] = useState(isDirectionRTL?"slideInRight":"slideInLeft");
    const [rightAnimation,setRightAnimation] = useState(isDirectionRTL?"slideInLeft":"slideInRight");

    const [fullName,setFullName] = useState("");
    const [email,setEmail] = useState("");
    const [phone,setPhone] = useState("");
    const [jobTitle,setJobTitle] = useState("");
    const [location,setLocation] = useState("");
    const [cv,setCv] = useState(null);

    useEffect(() => {
        if(routeChanged && currentRoute != match.path){
            setLeftAnimation(isDirectionRTL?"slideOutEasingRight":"slideOutEasingLeft");
            setRightAnimation(isDirectionRTL?"slideOutEasingLeft":"slideOutEasingRight");
        }
    }, [routeChanged]);


    const validate = ()=>{

        const failedTitles = {
            ar:"للاسف",
            en:"Sorry",
            ku:"للاسف",
            tr:"Sorry",
        }
        const failedTexts = {
            ar:"يرجى تعبئة جميع الحقول المطلوبة",
            en:"You must fill all required fields",
            ku:"يرجى تعبئة جميع الحقول المطلوبة",
            tr:"You must fill all required fields",
        }
        if(fullName == "" || email == "" || jobTitle == "" || phone == "" || cv == null){

            swal({
                title: failedTitles[locale.locale],
                text: failedTexts[locale.locale],
                icon: "error",
            });
            return false;
        }

        return true;
    }

    const sendCareers= () =>{

        if(!validate()) return;
        const successTitles = {
            ar:"تم",
            en:"Done",
            ku:"تم",
            tr:"Done",
        }
        const failedTitles = {
            ar:"للاسف",
            en:"Sorry",
            ku:"للاسف",
            tr:"Sorry",
        }
        const successTexts = {
            ar:"لقد تم إرسال المعلومات بنجاح",
            en:"Data has been sent successfully",
            ku:"لقد تم إرسال المعلومات بنجاح",
            tr:"Data has been sent successfully",
        }
        const failedTexts = {
            ar:"حدث خطأ لم يتم إرسال المعلومات",
            en:"Data has not sent",
            ku:"حدث خطأ لم يتم إرسال المعلومات",
            tr:"Data has not sent",
        }
        const formData = new FormData();
        formData.append("fullname",fullName);
        formData.append("email",email);
        formData.append("phoneNumber",phone);
        formData.append("jobTitle",jobTitle);
        formData.append("location",location);
        cv && formData.append("cv",cv);
        axios.post(APIs.sendCareers,formData).then(()=>
        {
            swal({
                title: successTitles[locale.locale],
                text: successTexts[locale.locale],
                icon: "success",
            });

        }).catch(()=>{
            swal({
                title: failedTitles[locale.locale],
                text: failedTexts[locale.locale],
                icon: "error",
            });
        })
    }


    const arabicClass = isDirectionRTL ? "container-fluid page-contents page-contents-rtl" : "container-fluid page-contents page-contents-ltr";

    return (
        <div className={arabicClass} id={"careers"}>

            <div className={"row"}>
                <div className={"col-lg-6 col-md-12 col-sm-12 section animated "+leftAnimation}>

                    <div className={"section-first-container"}>
                        <div className={"career-title"}>
                            <h3><IntlMessages id={"careers.title"}/></h3>
                        </div>
                       <div className={"jobs_list"}>
                           {
                               careersList && careersList.map((career,index)=>{
                                   console.log("career:",career);
                                   return(
                                       <div className={"jobs_item"}>
                                           <div className={"job-details"}>
                                               <h3><IntlMessages id={"careers.jobs.jobTitle"}/></h3>
                                               <span>{career.career_name[locale.locale]} </span>
                                           </div>
                                           <div className={"job-details"}>
                                               <h3><IntlMessages id={"careers.jobs.jobDescription"}/></h3>
                                               <span>{career.career_description[locale.locale]}</span>
                                           </div>
                                           <div className={"job-details"}>
                                               <h3><IntlMessages id={"careers.jobs.jobLocation"}/></h3>
                                               <span>{career.location}</span>
                                           </div>
                                           <div className={"job-details"}>
                                               <h3><IntlMessages id={"careers.jobs.jobSalary"}/></h3>
                                               <span>{career.salary && career.salary.length > 1 ?career.salary+"$":<IntlMessages id={"careers.unspecified"}/>}</span>
                                           </div>
                                       </div>
                                   )
                               })
                           }
                       </div>

                    </div>

                </div>
                <div className={"col-lg-6 col-md-12 col-sm-12 section animated "+rightAnimation}>
                    <div className={"section-second-container"}>
                        <form autoComplete={"off"}>
                            <div className={"form-title"}>
                                <h1><IntlMessages id={"careers.apply"}/></h1>
                            </div>
                            <div className="form-inputs">
                                <FormattedMessage id="careers.form.fullname" defaultMessage="search">
                                    {placeholder=>
                                        <Input size="small" onChange={v=>{
                                            setFullName(v.currentTarget.value)
                                        }} autoComplete={"off"} id={"fn-form"} placeholder={placeholder+" *"}/>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="careers.form.email" defaultMessage="search">
                                    {placeholder=>
                                        <Input size="small" onChange={v=>{
                                            setEmail(v.currentTarget.value)
                                        }} placeholder={placeholder+" *"}/>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="careers.form.phone" defaultMessage="search">
                                    {placeholder=>
                                        <Input size="small" onChange={v=>{
                                            setPhone(v.currentTarget.value)
                                        }} placeholder={placeholder+" *"}/>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="careers.form.jobtitle" defaultMessage="search">
                                    {placeholder=>
                                        <Input size="small" onChange={v=>{
                                            setJobTitle(v.currentTarget.value)
                                        }} placeholder={placeholder+" *"}/>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="careers.form.location" defaultMessage="search">
                                    {placeholder=>
                                        <Input size="small" onChange={v=>{
                                            setLocation(v.currentTarget.value)
                                        }} placeholder={placeholder}/>
                                    }
                                </FormattedMessage>
                                <div className={"upload-cv-btn"}>

                                    <input type={"file"} id={"upload-cv"} hidden={true} onChange={(file)=>{
                                        setCv(file.target.files[0]);
                                    }}/>
                                    <button type={"button"} onClick={()=>{
                                        document.getElementById("upload-cv").click();
                                    }}>{<IntlMessages id={"careers.form.uploadcv"}/>} *</button>
                                </div>

                                <div className={"apply-btn"}>

                                    <button onClick={sendCareers} type={"button"}>{<IntlMessages id={"careers.form.send"}/>}</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
        </div>
    )
}


export default connect(PageStates, PageDispatch)(Careers);
