import React, {useEffect, useState} from 'react';
import './footer.scss';
import {Link} from "react-router-dom";
import Images from "../../../constants/Images";
import PropTypes from 'prop-types'
import {connect} from "react-redux";
import {FooterStates} from "../../../reducers/connection/states";
import {FooterDispatch} from "../../../reducers/connection/functions";
import InjectMassage from "../../../util/IntlMessages";
import {FormattedMessage} from "react-intl";

const Footer = ({children, location, route_change, history, currentRoute, change_theme, isDirectionRTL}) => {

    const arabicClass = isDirectionRTL ? "footer-contents footer-contents-rtl" : "footer-contents footer-contents-ltr";
    const pageName = location.pathname.split("/")[1];

    const onClickLink = (url, func = null) => {

        if (currentRoute === url || !url) return;
        route_change(url);

        setTimeout(() => {
            history.push(url);

            func && func();
        }, 750);

    }

    return (
        <div id={"footer"} className={arabicClass+" page_"+pageName}>
            {pageName !== "category" && <div className={"sections row footer-contents"}>
                <div className={"col-lg-3 col-md-12 col-sm-12 address-section"}>

                    <h1>
                        <InjectMassage id={"contacts.address"}/><br/>

                        <FormattedMessage tagName={"small"} id={"footer.address"} />
                    </h1>
                    <h1>
                        <InjectMassage id={"contacts.phones"}/><br/>
                        <small>+964 750 317 0999 <br/>
                            +964 770 859 9559</small>
                    </h1>
                    <h1>
                        <InjectMassage id={"contacts.emails"}/><br/>
                        <small>info@karwanchigroup.com</small>
                    </h1>


                </div>
                <div className={"col-lg-5 col-md-12 col-sm-12 links-section row"}>
                    <div className={"col-lg-6 col-md-6 col-sm-12"}>
                        <ul>
                            <li onClick={() => {
                                onClickLink("/production", null);
                            }}><InjectMassage id={"menu.links.production"}/></li>
                            <li onClick={() => {
                                onClickLink("/certificates", null);
                            }}><InjectMassage id={"menu.links.certificates"}/></li>
                            <li onClick={() => {
                                onClickLink("/contact", null);
                            }}><InjectMassage id={"menu.links.contact"}/></li>
                        </ul>

                    </div>
                    <div className={"col-lg-6 col-md-6 col-sm-12"}>
                        <ul>
                            <li onClick={() => {
                                onClickLink("/careers", null);
                            }}><InjectMassage id={"menu.links.careers"}/></li>



                            <li onClick={() => {
                                onClickLink("/policy", null);
                            }}><InjectMassage id={"menu.links.privacy"}/></li>

                            <li></li>
                        </ul>

                    </div>
                </div>
                <div className={"col-lg-3 col-md-12 col-sm-12 logo-section"}>
                    <div className={"logo-container"}>
                        <img src={Images.logo}/>
                    </div>
                </div>
            </div>}
            <div className={"col-lg-12 footer-line"}>

                <span>Copyright © 2020 Karwanchi Group, All Rights Reserved</span>
            </div>
        </div>

    )
}


export default connect(FooterStates, FooterDispatch)(Footer);
