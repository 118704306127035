import appLocaleData from 'react-intl/locale-data/ku';
import kuMessages from '../locales/ku_IQ';

const KuLang = {
  messages: {
    ...kuMessages
  },
  rtl:true,
  label:"كوردى",
  locale: 'ku',
  data: appLocaleData
};
export default KuLang;
