export default {
  "menu.links.home": "Eve",
  "menu.links.products": "Ürünler",
  "menu.links.stuff": "Out Stuff",
  "menu.links.about": "Hakkımızda",
  "menu.links.contact": "İletişm",
  "menu.links.production": "Üretim Hatları",
  "menu.links.certificates": "Sertifikalar",
  "menu.links.careers": "Kariyer",
  "menu.links.legal": "Legal Information",
  "menu.links.privacy": "Gizlilik Politikası",
  "footer.address": "Iraq, Kirkuk, Altun Kupri",

  "home.brands.label": "We Are Poroud of Our",
  "home.brands.title": "Brands",

  "home.slider.karwanchigroup": "Karwanchi Group",
  "home.slider.text": "Karwanchi Şirketler Grubu, Irak ailesine daimi bir refakatçi haline gelen ürünleri ile ülkenin tüm illerinde yerel pazarlara hizmet veren en önemli şirketlerden biri olup, ürünlerin pazarlama mükemmelliği seviyesine ulaşmalarını sağlayan özellikler ile karakterize olmasından kaynaklanmaktadır.",
  "home.about.longtext":"",

  "home.read":"Daha",

  "home.productionLineTitle" : "Üretim Hatları",
  "home.productionLineShortDescription": "The current number of lines is 15 production lines,<br /> and 3 new lines will be added according to the<br />details listed below:<br />– Can Line A: The production capacity of 12600 c/h<br />– Can line B: Production capacity 12600 c/h",
  "home.productionLineLongDescription_1": "The current number of lines is 15 production lines, and 3 new lines will be added according to the details listed below:<br />" +
      "<br />" +
      "– Can Line A: The production capacity of 12600 c/h<br />" +
      "– Can line B: Production capacity 12600 c/h<br />" +
      "– Can line C: Production capacity 12600 c/h<br />" +
      "– Can Line D: Production capacity 12600 c/h<br />" +
      "– Glass line A: Production capacity 24000 bottles/hour<br />" +
      "– Glass line B: Production capacity 24000 bottles/hour<br />" +
      "– PET line No. 1: Production capacity 13000 plastic package/hour<br />" +
      "– PET line No. 2: Production capacity 24000 plastic bottles/hour<br />" +
      "– PET line No. 3 Capacity 24000 Plastic bottle/hour<br />" +
      "– line KHS metal box: production capacity 72000 packs/hour<br />" +
      "–Combibloc-SIG line 1: production capacity 24000 pack age/hour<br />" +
      "– Combibloc-SIG Line 2: Its production capacity is 24,000 package/hour",
  "home.productionLineLongDescription_2":  "– Combibloc – SIG Line 3: Production capacity 24000 package/hour<br />" +
      "– TETRAPAK line 1: Production capacity 24000 package/hour<br />" +
      "– TETRAPAK line 2: Production capacity 24000 package/hour<br />" +
      "– PET type KRONES from AZ: for packing juice with a capacity of 36000 plastic bottles / hour, it will run 1-1-2019 17<br />" +
      "– Line KRONES type cans from AZ: to pack the juice «Granules», with a capacity of 48000 packs / 1-2019 18<br />" +
      "– KRONES type glass line from AZ: for packing juices and soft drinks, with a production capacity of 55000 bottles / hour, it will run at 1-9-2019<br />" +
      "• The proportion of machinery operation of the total production capacity 80%",


  "home.certificateTitle": "Sertifikalar",
  "home.certificatesShortDescription": "The company has a license from the Directorate General for Industrial Development of Iraq with the number 81387, and also has a health certificate numbered 36 in",
  "home.certificatesLongDescription": "The company has a license from the Directorate General for Industrial Development of Iraq with the number 81387, and also has a health certificate numbered 36 in",


  "aboutUs.title": "Hakkımızda",

  "aboutUs.mission.title": "Karwanchi’s mission",
  "aboutUs.mission.text": "Karwanchi Group is looking forward to more innovation and is looking forward to the dream of\n" +
      "further expansion and leadership in wholesale and retail, and always promises you the best\n" +
      "products.",
  "aboutUs.vision.title": "Karwanchi’s vision",
  "aboutUs.vision.text": "Karwanchi Group is looking forward to more innovation and is looking forward to the dream of\n" +
      "further expansion and leadership in wholesale and retail, and always promises you the best\n" +
      "products.",

  "aboutUs.ourGoals.title": "Hedefimiz",
  "aboutUs.ourGoals.text": "Karwanchi Şirketler Grubu, daha fazla genişleme ve toptan ve perakende liderlik hayallerini göz önünde bulundurarak, daha fazla yenilik için sabırsızlanıyor ve her zaman size en iyi ürünleri sunmayı vaat ediyor.",
  "aboutUs.ourHistory.title": "Tarihimiz",
  "aboutUs.ourHistory.text": "Karwanchi Şirketler Grubu, Irak ailesine daimi bir refakatçi haline gelen ürünleri ile ülkenin tüm illerinde yerel pazarlara hizmet veren en önemli şirketlerden biri olup, ürünlerin pazarlama mükemmelliği seviyesine ulaşmalarını sağlayan özellikler ile karakterize olmasından kaynaklanmaktadır. <br /><br /> Karwanchi ailesinin kariyeri 1999 yılında başladı ve bu tarihten itibaren Karwanchi Grubu bu ürünlerde önemli Iraklı şirketler olarak lider pozisyonlara sahip oldu. Kariyerine 1999 yılında Kerkük ilinde, 11500 metrekarelik alanda, Tamim Firması ile 2 üretim hattı, cam ve gaz santrali ve 3 PET hattı ile, yolculuğa başladı. Şuanki mevcut kadrosu 160 kişidir. <br /><br />\n" +
      " 2007 yılında, 20,000 metrekarelik bir alanda başka bir şirket olan 6 üretim hattlı plastik bardak üretimi, 3 PET hattı ve 18 litreli su şişesi üretmek için Mina Sağlıklı Su Şirketi kurdu. Şuanki mevcut kadro sayısı 220 kişidir. <br /><br />\n" +
      " Karwanchi Alkolsüz İçecekler, Sağlıklı Su, Meyve Suları ve Süt Ürünleri Limited Şirketi, 2010 yılında 150,000 metrekarelik alan üzerinde kurulmuş olup, bunun 60,000 metrekaresi kapalı alan olup, üretim hatları ve depo amaçlı, 20,000 metrekarelik üretim depolama alanı, 20,000 metrekarelik alanı ise tipik bahçeler ve yeşil alanları, 25,000 metrekarelik  iç cadde ve dahili yolları ve 25,000 metrekarelik alanı da araştırma ve geliştirme için tahsis edilmiştir. <br /><br />\n" +
      " Şirket, 81387 sayılı Irak Sanayi Geliştirme Genel Müdürlüğü’nden bir kurucu lisansına sahip olup, aynı zamanda ISO 9001: 2008 & 22000: 2005 ve Halal sertifikası belgelerine ek olarak 36 numaralı sağlık sertifikasına sahiptir. <br /><br />\n" +
      " Karwanchi Şirketler Grubu birçok uluslararası ve yerel sergilere katılıp ve birçok etkinliğe katıldığından dolayı birçok ödülere laik görülmüştür.\n",


  "privacy.text": "Karwanchi Group or related companies undertake and declare to accept that will do not sell, hire or use and handle personal data (hereinafter referred to as “Personal Information”) such as your name, electronic post address, work or home addresses and telephone numbers that submitted via the forms lled out by the visitors to any third persons.<br />" +
      "<br />" +
      "Karwanchi Group or related companies will use and handle the personal information as limited as possible for the aims mentioned herein under; sending Pressed publications/ correspondence, sending press bulletin by electronic posts, the materials purchase and/or deliver rewards.<br />" +
      "<br />" +
      "Personal Information can only be reached by Karwanchi Group or related companie’s authorized personnel and our third person representatives who accepted the personal information to be condential. Karwanchi Group or related companies can without declaring the Personal Information and only in case to obtain Personal Information’s statistical data (Scanner type, geographical location, age, gender .. etc.) can use and handle the Personal Information in the purpose of developing its Web Site and in general to collect more information concerning the consumers. All rights gained upon the said statistical data is reserved to Karwanchi Group or related companies.<br />" +
      "<br />" +
      "Karwanchi Group or related companies cannot guarantee the subject matter regarding the condential concepts of the web sites of the third persons that has reached, therefore we recommend to evaluate the condential matter approach of the sites you head for before submitting any information that could be declared as a private.",


  "category.view_products": "Ürünleri Görüntüle",
  "category.back_products": "Geri",

  "contacts.address": "Adres",
  "contacts.phones": "Telefonlar",
  "contacts.emails": "E-posta",

  "careers.title": "Kariyer",
  "careers.apply": "İşe başvurmak",
  "careers.form.fullname": "Ad Soyad",
  "careers.form.email": "E-posta",
  "careers.form.phone": "Telefon numarası",
  "careers.form.jobtitle": "İş ismi",
  "careers.form.location": "İkamet yeri",
  "careers.form.uploadcv": "Özgeçmişinizi yükleyin",
  "careers.form.send": "Uygulamak",

  "careers.jobs.jobTitle": "İş ismi",
  "careers.jobs.jobDescription": "İş tanımı",
  "careers.jobs.jobSalary": "Maaş",
  "careers.jobs.jobLocation": "Yer",

  "careers.unspecified": "Belirtilmemiş",
  "careers.success.title": "Bitti",
  "careers.success.message": "mesaj gönderildi",

  "contactus.title": "İletişm",
  "contactus.form.message": "İleti",
  "contactus.form.send": "Gönder"

}
