import React, {useEffect, useState} from 'react';
// import '../App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory
} from "react-router-dom";

import Home from "./routes/home";
import Page from "./routes/page";
import Careers from "./routes/careers";
import Contact from "./routes/contact";
import Certificates from "./routes/certificates";
import About from "./routes/about";
import Category from "./routes/category";
import {connect} from "react-redux";
import {NavBarStates} from "../../reducers/connection/states";
import {NavBarDispatch} from "../../reducers/connection/functions";
import ProductionLine from "./routes/productionLine";
import Policy from "./routes/policy";

const App = ({match, categories, location, locale, authUser, initURL, isDirectionRTL}) => {


    useEffect(() => {

        window.onpopstate = (e) => {
            const href = e.target.location.href;
            const hrefSplit = href.split("");
            const hrefLength = hrefSplit.length;
            const lastCharachter = hrefSplit[hrefLength - 1];

            if (lastCharachter != "#") {
                e.path[0].location.reload()
            }
            // console.log(hrefSplit[hrefLength-1])

        }
    }, [])


    return (
        <Switch>
            <Route path={"/"} exact component={Home}/>
            <Route path={"/category"} component={Category}/>
            {
                categories && Array.isArray(categories) && categories.map((cate) => {
                    return (<Route path={"/category/" + cate.slug} component={Category}/>)
                })
            }

            <Route path={"/production"} component={ProductionLine}/>
            <Route path={"/policy"} component={Policy}/>
            <Route path={"/careers"} component={Careers}/>
            <Route path={"/contact"} component={Contact}/>
            <Route path={"/certificates"} component={Certificates}/>
            <Route path={"/about"} component={About}/>
            <Route path={"/page"} component={Page}/>
        </Switch>
    );
}

export default connect(NavBarStates, NavBarDispatch)(App);
