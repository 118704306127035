import {
    change_theme,
    change_language,
    fetch_categories,
    fetch_careers,
    select_category,
    select_product,
    change_display_mode,
    route_change
} from "../../actions";


export function NavBarDispatch(dispatch) {
    return {
        change_theme: (payload) => dispatch(change_theme(payload)),
        change_language: (payload) => dispatch(change_language(payload)),
        select_category: (payload) => dispatch(select_category(payload)),
        route_change: (payload) => dispatch(route_change(payload)),
        change_display_mode: (mode) => dispatch(change_display_mode(mode))
    }
}

export function AppDispatch(dispatch) {
    return {
        change_theme: (payload) => dispatch(change_theme(payload)),
        fetch_categories: (payload) => dispatch(fetch_categories(payload)),
        fetch_careers: (payload) => dispatch(fetch_careers(payload)),
    }
}

export function CategoryDispatch(dispatch) {
    return {
        change_theme: (payload) => dispatch(change_theme(payload)),
        fetch_categories: (payload) => dispatch(fetch_categories(payload)),
        select_category: (payload) => dispatch(select_category(payload)),
        select_product: (payload) => dispatch(select_product(payload)),
        change_display_mode: (mode) => dispatch(change_display_mode(mode))
    }
}

export function PageDispatch(dispatch) {
    return {
        change_theme: (payload) => dispatch(change_theme(payload)),
        route_change: (payload) => dispatch(route_change(payload)),
        select_product: (payload) => dispatch(select_product(payload)),
        select_category: (payload) => dispatch(select_category(payload)),
        change_display_mode: (mode) => dispatch(change_display_mode(mode))

    }
}

export function FooterDispatch(dispatch) {
    return {
        change_theme: (payload) => dispatch(change_theme(payload)),
        route_change: (payload) => dispatch(route_change(payload)),
    }
}

