import React, {useEffect} from 'react';
import {
    BrowserRouter as Router, Route, Link, Switch
} from 'react-router-dom';
import AppLocale from '../lngProvider';

// import RTL from 'util/RTL';
// import asyncComponent from 'util/asyncComponent';

import Main from "./app";
import Static from "../components/Static";
import {connect} from "react-redux";
import {AppStates} from "../reducers/connection/states";
import {AppDispatch} from "../reducers/connection/functions";
import {IntlProvider} from "react-intl";
import {getAllCareers, getAllCategories} from "../constants/Helper";
import SocialMedia from "../components/Static/Social";

const App = ({locale, isDirectionRTL,match,location,fetch_categories,fetch_careers,history}) => {

    const currentAppLocale = AppLocale[locale.locale];

    useEffect(()=>{
        getCategories();
        getCareers();
    },[]);



    if (isDirectionRTL) {
        document.body.classList.add('rtl')
    } else {
        document.body.classList.remove('rtl');
    }


    const getCategories = ()=>{
        getAllCategories().then((s)=>{
            fetch_categories(s.data)
        }).catch((e)=>{
            console.log(e);
            }
        )
    }
    const getCareers = ()=>{
        getAllCareers().then((s)=>{
            fetch_careers(s.data)
        }).catch((e)=>{
            console.log(e);
            }
        )
    }

    return (

            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>
                <Static location={location} history={history} match={match}>
                    <Switch>
                        <Route path="/" component={Main}/>
                    </Switch>
                </Static>
            </IntlProvider>

    );
}

export default connect(AppStates, AppDispatch)(App);
