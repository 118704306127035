import React, {useEffect, useRef, useState} from 'react';
import {animated, useChain, useTransition, config} from "react-spring";
import './itemsList.scss'

const ItemsList = ({className,routeChanged, sectionContents, onClickItem, locale}) => {


    const [renderedList, setRenderedList] = useState([]);

    useEffect(() => {

        if (sectionContents.length) {
            setRenderedList(sectionContents);
        }

    }, [sectionContents]);

    useEffect(()=>{
        setRenderedList([]);
    },[routeChanged])

    return (
        <div className={className}>
            <ul>
                {
                    renderedList.map((item, index) => {
                            const name = item.category_name[locale] ? item.category_name[locale] : item.product_name[locale];
                            const className = sectionContents.length == 0 ? "animated bounceOut fast" : "animated bounceIn fast";
                            return (
                                <li className={className + " col-lg-4 col-md-6 col-sm-6"} onClick={() => {
                                    onClickItem(item)
                                }} key={item.id}>
                                    <div className={"item-image"}>
                                        <img src={item.image_file_full}/>
                                    </div>
                                    <div className={"item-btn"}>
                                        <a href={"#"}><span>{name}</span></a>
                                    </div>
                                </li>
                            )
                        }

                    )
                }

            </ul>
        </div>
    )
}


export default ItemsList;
