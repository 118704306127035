import appLocaleData from 'react-intl/locale-data/en';
import enMessages from '../locales/en_US';

const EnLang = {
  messages: {
    ...enMessages
  },
  rtl:false,
  label:"English",
  locale: 'en',
  data: appLocaleData
};
export default EnLang;
