import React, {useEffect, useRef, useState} from 'react';
import './social.scss';
import images from '../../../constants/Images';
import {Link} from "react-router-dom";
import {animated, useChain, useTransition} from "react-spring";
import {connect} from "react-redux";
import {NavBarStates} from "../../../reducers/connection/states";
import {NavBarDispatch} from "../../../reducers/connection/functions";
import windowSize from 'react-window-size';


const {logo, facebook, instagram, linkedin, youtube, twitter} = images;


const SocialMedia = ({locale,isDirectionRTL}) => {

    const links = [
        {
            img:facebook,
            link:"https://www.facebook.com/karwanchigroupiraq"
        },
        {
            img:instagram,
            link:"https://www.instagram.com/karwanchigroup/"
        },
        {
            img:twitter,
            link:"https://twitter.com/karwanchigroup"
        },
        {
            img:linkedin,
            link:"https://www.linkedin.com/company/karwanchigroup/"
        },
        {
            img:youtube,
            link:"https://www.youtube.com/channel/UCR7vXfnAoVrynrUAlPhubAw"
        }
    ]
    const transRef = useRef()
    const transitions = useTransition(links, item => item.img, {
        ref: transRef,
        unique: true,
        trail: 700 / links.length,
        from: {opacity: 0, transform: 'scale(0)'},
        enter: {opacity: 1, transform: 'scale(1)'},
        leave: {opacity: 0, transform: 'scale(0)'}
    })


    useChain([transRef], [0]);


    const arabicClass = isDirectionRTL?"socialMedia socialMediaRTL":"socialMedia socialMediaLTR";


    return (
        <div className={arabicClass}>
            <div className={"socialMediaList"}>
                <ul>
                    {transitions.map(({item, key, props}) => (
                        <animated.li key={key} style={{...props}}>

                            <a href={item.link}>
                                <img src={item.img}/>
                            </a>
                        </animated.li>
                    ))}

                </ul>
            </div>
        </div>

    )
}


export default connect(NavBarStates, NavBarDispatch)(windowSize(SocialMedia));
