const webserver = "https://backend.karwanchigroup.com";
// const webserver = "http://127.0.0.1:8000";

export default {
    getSettingsInfo:`${webserver}/api/app/getSettings`,

    getCareers:`${webserver}/api/app/getCareers`,
    sendCareers:`${webserver}/api/app/sendCareers`,
    sendContactUs:`${webserver}/api/app/sendContactUs`,

    getCategories:`${webserver}/api/app/getCategories`,

    getNewsLetters:`${webserver}/api/getNewsLetters`,

    getLanguages:`${webserver}/api/app/getLanguages`,

}
