import React, {useEffect, useRef, useState} from "react";
import './category.scss';
import Images from '../../../../constants/Images';
import {useChain, useSpring, animated, useTransition} from "react-spring";
import {Keyframes, animated as AnimatedComponent, config} from 'react-spring/renderprops'
import InjectMassage from "../../../../util/IntlMessages";
import {connect} from "react-redux";
import {CategoryStates, NavBarStates} from "../../../../reducers/connection/states";
import {CategoryDispatch, NavBarDispatch} from "../../../../reducers/connection/functions";
import ProductInfo from "../../../../components/ProductInfo";
import ItemsList from "../../../../components/Static/ItemsList";
import {checkParams} from "../../../../constants/Helper";
import Corner from "../../../../components/Corner";

const default_description = "is one of the most important suppliers in the local market in all the governorates of the country with its products which became a permanent companion to the Iraqi family."
const Category = ({match, isDirectionRTL, viewCategories, change_display_mode, selectedProduct, select_product, locale, selectedCategory, routeChanged, currentRoute}) => {

    const duration = 1400;
    const [toggleList, setToggleList] = useState(false);
    const [viewProducts, setViewProducts] = useState(false);
    const [history, setHistory] = useState([]);
    const [sectionContents, setSectionContents] = useState([]);
    const [sectionContentType, setSectionContentType] = useState("categories");

    const [leftAnimation, setLeftAnimation] = useState("slideInLeft");
    const [rightAnimation, setRightAnimation] = useState("slideInRight");

    const [currentCategory, setCurrentCategory] = useState(null);

    const [timestamp, setTimestamp] = useState(Date.now());
    const springRef = useRef();
    const {size, opacity, ...rest} = useSpring({
        ref: springRef,
        config: config.stiff,
        from: {size: '20%', background: 'hotpink'},
        to: {size: viewProducts ? '100%' : '20%', background: viewProducts ? 'white' : 'hotpink'}
    });


    useChain([springRef], [0]);


    const image1Ref = useRef();
    const image2Ref = useRef();
    const image3Ref = useRef();
    const image4Ref = useRef();
    const image5Ref = useRef();
    const image6Ref = useRef();
    const images_entering = [
        useSpring({
            ref: image1Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image2Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image3Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image4Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image5Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1) translateX(0px)", opacity: 1}
        }),
        useSpring({
            ref: image6Ref,
            config: {duration: duration},
            from: {transform: "scale(0) translateX(-600px)", opacity: 0},
            to: {transform: "scale(1) translateX(0px)", opacity: 1}
        })
    ];


    // This will orchestrate the two animations above, comment the last arg and it creates a sequence
    useChain([image1Ref, image2Ref, image3Ref, image4Ref, image5Ref, image6Ref], [1, 1.5, 1, 1.5, 2, 2.5]);


    const AnimFeTurbulence = animated('feTurbulence');
    const AnimFeDisplacementMap = animated('feDisplacementMap');


    const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];

    const images_movement = [
        (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 20}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 30}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 40}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 50}px,${y / 10}px,0)`,
        (x, y) => `translate3d(${x / 50}px,${y / 10}px,0)`
    ];


    const [props, set] = useSpring(() => ({xy: [0, 0], config: {mass: 10, tension: 550, friction: 140}}));


    useEffect(() => {
        setHistory([]);
        setCurrentCategory(null);

        // currentRoute && alert(currentRoute.split("/").slice(-1));

        // currentRoute && console.log(currentRoute.split("/").slice(-1),selectedCategory.slug);

        if (routeChanged && selectedCategory && currentRoute.split("/").slice(-1) != selectedCategory.slug) {
            setLeftAnimation(isDirectionRTL ? "slideOutEasingRight" : "slideOutEasingLeft");
            setRightAnimation(isDirectionRTL ? "slideOutEasingLeft" : "slideOutEasingRight");

            setViewProducts(false);
            setSectionContents([]);
            setSectionContentType("categories");
            setHistory([]);
            select_product(null);
        }
        change_display_mode(false);

    }, [routeChanged]);

    useEffect(() => {
        setLeftAnimation(isDirectionRTL ? "slideInRight delay-0.5s" : "slideInLeft delay-0.5s");
        setRightAnimation(isDirectionRTL ? "slideInLeft delay-0.5s" : "slideInRight delay-0.5s");


        setTimestamp(Date.now());
    }, [selectedCategory]);


    const makeList = (category = selectedCategory, backEvent = false) => {


        if (!backEvent && currentCategory) {
            setHistory([...history, currentCategory]);
        }
        setCurrentCategory(category);

        setToggleList(false);

        setSectionContents([]);

        setTimeout(() => {
            if (category.subCategories.length > 0) {
                setSectionContents(category.subCategories);
                setSectionContentType("categories");
            } else {
                setSectionContents(category.products);
                setSectionContentType("products");
            }
        }, sectionContents.length ? 1000 : 500)

    };


    const onBack = () => {
        const _history = history;
        const lastItem = _history.pop();
        setHistory(_history);
        makeList(lastItem, true);
    };

    const slug = selectedCategory ? selectedCategory.slug : "";


    const categoryImages = slug && Images.categories[slug];

    const categoryImage = selectedCategory && checkParams(categoryImages, ['logo', locale.locale], checkParams(selectedCategory, ['image_file_full'], ""));
    const categoryDescription = selectedCategory && checkParams(selectedCategory, ['category_description', locale.locale], default_description);

    const intoImages = viewProducts ? "bounceOut" : "fadeIn";

    const side = isDirectionRTL ? "RTL" : "LTR";
    const product_info_open = selectedProduct ? "product_info_open_" + side : "";

    const arabicClass = isDirectionRTL ? "page-contents page-contents-rtl" : "page-contents page-contents-ltr";


    const water_line_animation = viewProducts ? "bounceOutDown" : "karwnchi_water_line_loop bounceInDown";

    const {detect} = require('detect-browser');
    const browser = detect();


    return (
        <div id={"category-page"} className={arabicClass + " browser_" + browser.name}
             onMouseMove={({clientX: x, clientY: y}) => set({xy: calc(x, y)})}>
            {/*<canvas id="canvas"></canvas>*/}


            {slug == "karwnchi_water" && <div className={"karwnchi_water_line animated " + water_line_animation}
                                              style={{backgroundImage: `url(${Images.category_3_karwnchi_water_line})`}}/>}

            {/*{slug == "fire_ball" && <div className={"gif-background animated "+rightAnimation}>*/}
            {/*    <img src={Images.categories.fire_ball.gif[0]} />*/}
            {/*</div>}*/}


            <div className={"container-fluid"}>
                <div className={"row"} id={"first-section"}>
                    {slug !== "fire_ball" && <div className={"image-background animated sliding"}>
                        <img id={"image-background"}
                             src={Images.categories[slug] ? Images.categories[slug].background : null}/>
                    </div>}

                    <div className={"col-lg-4 col-md-5 col-sm-12 section-1 animated " + leftAnimation}>
                        <div className={"slide-text " + product_info_open}>
                            <div className={"category_img"}>
                                {categoryImage && <img src={categoryImage}/>}
                            </div>
                            <div className={"category_description"}>
                                <span>{categoryDescription}</span>
                            </div>
                            <div className={"category_products_btn"}>
                                {(history.length > 0 || !viewProducts) && <a href={"#"} onClick={() => {
                                    if (!viewProducts) {
                                        makeList();
                                        setViewProducts(true);

                                    } else {
                                        onBack();
                                    }
                                    select_product(null);
                                    change_display_mode(true);
                                }}>{viewProducts ? <InjectMassage tagName={"span"} id={"category.back_products"}/> :
                                    <InjectMassage tagName={"span"} id={"category.view_products"}/>}</a>}
                            </div>
                        </div>
                    </div>

                    <div className={"col-lg-8 col-md-7 col-sm-12 section-2 animated " + rightAnimation}>
                        <div className={"section-contents"}>
                            <ItemsList className={"contents-list " + product_info_open} locale={locale.locale}
                                       onClickItem={(item) => {
                                           if (item.hasOwnProperty("products")) {
                                               makeList(item);
                                               select_product(null);
                                           } else {

                                               if (!selectedProduct || item.id != selectedProduct.id) {
                                                   select_product(item);
                                               } else {
                                                   select_product(null);
                                               }


                                           }
                                       }} routeChanged={routeChanged} sectionContents={sectionContents}/>
                            <div className={"slide-images faster animated " + intoImages + " browser_" + browser.name}>

                                {
                                    Images.categories[slug] && Images.categories[slug].images.map((img, index) => {
                                        return (
                                            <animated.div key={"image_" + timestamp + "_" + index}
                                                          style={images_entering[index]}
                                                          className={"browser_" + browser.name + " image-" + index}>

                                                <animated.img
                                                    style={{transform: props.xy.interpolate(images_movement[index])}}
                                                    src={img}/>
                                            </animated.div>
                                        )
                                    })
                                }

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <Corner/>
        </div>
    )
}

export default connect(CategoryStates, CategoryDispatch)(Category);
