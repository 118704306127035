export function GeneralStates({theme,route}) {

    const {
        themeColor,
        themeFooterBackgroundGradient,
        themeFooterLineGradient,
        themePageBackgroundGradient,
        isDirectionRTL,
        locale
    } = theme;

    const {routeChanged,currentRoute} = route;

    return {
        themeColor,
        themeFooterBackgroundGradient,
        themeFooterLineGradient,
        themePageBackgroundGradient,
        isDirectionRTL,
        locale,
        routeChanged,
        currentRoute
    }
}

export function AppStates({theme,route}) {

    return {
        ...GeneralStates({theme,route}),
    }
}

export function NavBarStates({theme,data,route}) {
    return {
        selectedCategory:data.selected_category,
        selectedProduct:data.selected_product,
        categories:data.categories_list,
        ...GeneralStates({theme,route}),
    }
}

export function CategoryStates({theme,data,route}) {
    return {
        selectedCategory:data.selected_category,
        selectedProduct:data.selected_product,
        categories:data.categories_list,
        ...GeneralStates({theme,route}),
    }
}

export function PageStates({theme,data,route}) {

    const {careers_list} = data;
    return {
        selectedCategory:data.selected_category,
        selectedProduct:data.selected_product,
        careersList:careers_list,
        viewCategories:data.viewCategories,
        categories:data.categories_list,
        ...GeneralStates({theme,route}),
    }
}

export function FooterStates({theme,route}) {

    return {
        ...GeneralStates({theme,route}),
    }
}
