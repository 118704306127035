import React from 'react';
import {ConnectedRouter} from 'connected-react-router'
import {Provider} from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import configureStore, {history} from './store';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import App from './containers';

export const store = configureStore();

const MainApp = () =>
  <Provider store={store}>
    <ConnectedRouter history={history}>
        <Router>
            <Route path="/" component={App}/>
        </Router>
    </ConnectedRouter>
  </Provider>;


export default MainApp;
