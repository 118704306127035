import React, {useEffect, useRef, useState} from 'react';
import './languageOptions.scss';
import {connect} from "react-redux";
import {NavBarStates} from "../../../reducers/connection/states";
import {NavBarDispatch} from "../../../reducers/connection/functions";
import AppLocale from "../../../lngProvider";
import {animated, config, useChain, useSpring, useTransition} from "react-spring";


const LanguageOptions = ({isDirectionRTL, locale, change_language, change_theme, themeColor, themeFooterBackgroundGradient, themeFooterLineGradient, themePageBackgroundGradient}) => {

    const initialMenu = {height: 0, from: {height: 0}};
    // const openOptions = {height: screenHeight, from: {height: 0}};
    // const closeOptions = {height: 0, from: {height: screenHeight}};

    const [toggleOptions, setToggleOptions] = useState(false);
    const [optionsStyle, setOptionsStyle] = useState(initialMenu);

    const languages = Object.values(AppLocale);
    const [open, set] = useState(false);

    const springRef = useRef();
    const listAnimation = useSpring({
        ref: springRef,
        config: {duration: 500},
        from: {transform: "scaleY(0)"},
        to: {transform: open?"scaleY(1)":"scaleY(0)"},
    });

    const transRef = useRef()
    const transitions = useTransition(open ? languages : [], item => item.locale, {
        ref: transRef,
        unique: true,
        trail: 300 / languages.length,
        from: {opacity: 0, transform: 'scale(0)'},
        enter: {opacity: 1, transform: 'scale(1)'},
        leave: {opacity: 0, transform: 'scale(0)'}
    })

    // This will orchestrate the two animations above, comment the last arg and it creates a sequence
    useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.3 : 0]);


    return (
        <div id={"language-options"}>
            <div className={"language-label"}>
                <a onClick={() => {
                    set(open => !open);
                }}>
                    {locale.locale}
                </a>
            </div>
            <div className={"options-list-container"}>
                 <ul>
                    {languages.map((item,index, ) => {


                        return (
                            <li key={"option-item-"+index} className={locale.locale === item.locale ? "selected" : null} onClick={() => {
                                change_language(item)
                            }} id={"item-"+index}>
                                {item.locale}
                            </li>
                        )
                    })}
                </ul>
            </div>
{/*
            <animated.div style={listAnimation} className={"options-list-container"}>
                 <ul>
                    {transitions.map(({item, key, props}) => {
                        return (
                            <animated.li className={locale.locale === item.locale ? "selected" : null} onClick={() => {
                                change_language(item)
                            }} key={key} style={{...props}}>
                                {item.locale}
                            </animated.li>
                        )
                    })}
                </ul>
            </animated.div>
*/}
        </div>

    )
}


export default connect(NavBarStates, NavBarDispatch)(LanguageOptions);
