export default {
    "menu.links.home": "الرئيسية",
    "menu.links.products": "منتجاتنا",
    "menu.links.stuff": "فريقنا",
    "menu.links.about": "من نحن",
    "menu.links.contact": "اتصل بنا",
    "menu.links.production": "خط الإنتاج",
    "menu.links.certificates": "شهادات الجودة",
    "menu.links.careers": "وظائف",
    "menu.links.legal": "معلومات قانونية",
    "menu.links.privacy": "سياسة الخصوصية",
    "footer.address": "العراق،كركوك ، التون كوبرى",

    "home.brands.label": "نفتخر بعلاماتنا التجارية",
    "home.brands.title": " ",

    "home.slider.karwanchigroup": "فريق كرونجي",
    "home.slider.text": "تُعد مجموعة شركات كرونجي إحدى أهم الشركات التي ترفد الأسواق المحلية في كافة محافظات البلاد بمنتوجاتها التي أصبحت رفيقةً دائمة للأسرة العراقية، ويعود السبب في ذلك إلى تحلّي منتوجاتها بالمواصفات التي مكّنتها للوصول إلى المستوى التسويقي المتميز.",

    "home.read":"المزيد",
    "home.productionLineTitle" : "خط الإنتاج",
    "home.productionLineShortDescription": "تبلغ عدد الخطوط الحالية 15 خط إنتاجي، وسيتم إضافة 3 خطوط جديدة حسب التفاصيل المدرجة أدناه:<br />" +
        "1 -خط قدح A : طاقته الإنتاجية 12600 قدح/ساعة<br />" +
        "2 -خط قدح B : طاقته الإنتاجية 12600 قدح/ساعة<br />" +
        "3 -خط قدح C : طاقته الإنتاجية 12600 قدح/ساعة",
    "home.productionLineLongDescription_1": "تبلغ عدد الخطوط الحالية 15 خط إنتاجي، وسيتم إضافة 3 خطوط جديدة حسب التفاصيل المدرجة أدناه:<br />" +
        "1 -خط قدح A :طاقته الإنتاجية 12600 قدح/ساعة<br />" +
        "2 -خطقدحB:طاقته الإنتاجية 12600 قدح/ساعة<br />" +
        "3 -خط قدح C:طاقته الإنتاجية 12600 قدح/ساعة<br />" +
        "4 -خطقدحD:طاقته الإنتاجية 12600 قدح/ساعة<br />" +
        "5 -خط زجاجي A :طاقته الإنتاجية 24000زجاجة/ساعة<br />" +
        "6 -خط زجاجي B :طاقته الإنتاجية 24000زجاجة/ساعة<br />" +
        "7 -خطPET رقم 1:طاقتهالإنتاجية13000 عبوة بلاستيكية/ساعة<br />" +
        "8 -خطPET رقم 2:طاقته الإنتاجية 24000 عبوة بلاستيكية/ساعة<br />" +
        "9 -خطPET رقم 3:طاقته الإنتاجية 24000 عبوة بلاستيكية/ساعة<br />" +
        "10 -خط علبة معدنية KHS :طاقته الإنتاجية 72000 علبة/ساعة<br />" +
        "11 -خط SIG- Combiblocرقم1 :طاقته الإنتاجية 24000 علبة كرتونية/ساعة<br />" +
        "12 -خطSIG- Combiblocرقم2:طاقته الإنتاجية 24000 علبة كرتونية/ساعة<br />",
    "home.productionLineLongDescription_2":"13 -خطSIG- Combiblocرقم3 :طاقته الإنتاجية 24000 علبة كرتونية/ساعة<br />" +
        "14 -خط TETRAPAKرقم1:طاقته الإنتاجية 24000 علبة كرتونية/ساعة<br />" +
        "15 -خط TETRAPAKرقم 2 :طاقته الإنتاجية 24000 علبة كرتونية/ساعة<br />" +
        "16 -خط PETنوع KRONES من Z-A :لتعبئة العصò بطاقة إنتاجية 36000 عبوة بلاستيكية/ساعة، سيتم تشغيله 1-1-2019<br />" +
        "17 -خط علب معدنية نوعKRONES من Z-A : ُ لتعبئة العصò» حبيبات»، بطاقة إنتاجية 48000 علبة/ساعة،سيتم تشغيله 1-1-2019<br />" +
        "18 -خط زجاجي نوع KRONESمنZ-A :لتعبئة العصائر والمشروبات الغازية، بطاقة إنتاجية 55000 زجاجة/ساعة،سيتم تشغيله 1-9-2019<br />" +
        "نسبة تشغيل المكائن من مجمل الطاقة الإنتاجية 80٪",


    "home.certificateTitle": "شهادات الجودة",
    "home.certificatesShortDescription": "الشركة حاصلة على إجازة تأسيس من المديرية العامة للتنمية الصناعية العراقية ذات رقم 81387، وحاصلة أيضاً على إجازة صحية المرقّمة 36 بالإضافة إلى شهادات الآيزو 2008:9001 & 2005:22000 وشهادة حلال.",
    "home.certificatesLongDescription": "الشركة حاصلة على إجازة تأسيس من المديرية العامة للتنمية الصناعية العراقية ذات رقم 81387، وحاصلة أيضاً على إجازة صحية المرقّمة 36 بالإضافة إلى شهادات الآيزو 2008:9001 & 2005:22000 وشهادة حلال.",


    "aboutUs.title": "من نحن",
    "aboutUs.mission.title": "مهمتنا",
    "aboutUs.mission.text": "تسعى دائما شركة كروانجي أن تبتكر منتجات طبيعية، باستخدام أحدث الوسائل التقنية و أفضل خطوط الانتاج لكي تلائم ذوق المستهلك، وأن تقدم منتجات بمعايير جودة عالمية بأسعار تنافسية لكافة المستهلكين في جميع أنحاء العراق.",
    "aboutUs.vision.title": "رؤيتنا",
    "aboutUs.vision.text": "أن رؤية شركة كرونجي تتمركز حول جعل المنتجات التي تقدمها هي الرائدة والملهمة في الأسواق المحلية والعالمية، من خلال ارتقائها و تميزها في توفير منتجات طبيعية 100% لمواكبة نمط الحياة الصحية المناسبة للمستهلك.",
    "aboutUs.ourGoals.title": "هدفنا",
    "aboutUs.ourGoals.text": "تتطلع مجموعة شركات كرونجي قدماً إلى المزيد الابتكار وتضع نصب عينيها حلم المزيد من التوسع والريادة في مجالي بيع الجملة والتجزئة، وتعدكم دائماَ بتقديم أجود ما لديها من المنتجات",
    "aboutUs.ourHistory.title": "تاريخنا",
    "aboutUs.ourHistory.text": "اليوم تعد مجموعة شركات كرونجي واحدة من أكبر الشركات الصناعية في العراق، فهي تمتلك قاعدة جماهيرية كبيرة من قبل المستهلكين في العراق مكنتها من التميز والوصول الى موقع ريادي في الأسواق بسبب الجودة العالية والكفاءة التي تقدمها في منتجاتها.<br />" +
        "كانت بداية وتأسيس رحلة مجموعة كرونجي من كركوك حيث تأسست أول شركة بأسم\" التأميم \" للمشروبات الغازية سنة 1999 على أرض بمساحة 11,500 متر مربع بخطين زجاجيين ومعمل غاز وثلاث خطوط  PET، وبعدها في 2007 تم تأسيس شركة \"مِنى\" للمياه الصحية على أرض بمساحة 20,000 متر مربع وبـ 6 خطوط انتاج للأقداح البلاستيكية و 3 خطوط PET و 3 خطوط لإنتاج قوارير الماء سعة 18 لتر ، وفي سنة 2010 تم تأسيس شركة كرونجي للمشروبات الغازية والمياه الصحية والعصائر والألبان المحدودة على مساحة 160.000 متر مربع. ولان مجموعة شركات كرونجي  تطمح دائما أن تقديم الأفضل ل مستهلكيها، فإنها تعمل وفق معايير عالمية مكنتها من الحصول على شهادة الايزو العالمية الخاصة بمعايير سلامة الأغذية. وكذلك فأنها تمتلك نظام إداري داخلي متكامل وكفوء مكنها من الحصول على شهادة أيزو اخرى خاصة بنظام إدارة الجودة بالاضافة إلى شهادة الحلال.<br />",

    "privacy.text":"مجموعة شركات الكرونجي والشركات التابعة تتعهد على ان لا يتم بيع, استئجار او استخدام اي بيانات شخصية ( المشار اليها في ما يلي بالعبارة” المعلومات<br />" +
        "الشخصية”) كالأسم, عنوان الوظيفة الألكترونية وعناوين الوظيفة والسكن وارقام الهواتف التي كتبت على الاستãرات لغرض الزيارة الى اي شخص ثالث.<br />" +
        "مجموعة شركات الكرونجي والشركات التابعة تستخدم المعلومات الشخصية بشكل محدود بقدر الامكان لأسباب الواردة: ارسال منشورات صحفية\\محاورات,<br />" +
        "ارسال نشرة صحفية من قبل المشاركات الالكترونية او شراء المواد او تسليم المكافئات.<br />" +
        "المعلومات الشخصية متاحة فقط للمخول· من مجموعة شركات الكرونجي والشركات التابعة والطرف الثالث الذي يتعهد على الحفاظ على سرية المعلومات.<br />" +
        "مجموعة شركات الكرونجي والشركات التابعة تحافظ على خصوصية البيانات والمعلومات الشخصية من الاستنساخ او النشر بأي طريقة, ما اذا كان عن طريق<br />" +
        "(الماسح الضوì ,نظام تحديد المواقع العالمية, بيانات رقمية, معلومات عن الجنس\\العمر, الخ.).<br />" +
        "مجموعة شركات الكرونجي والشركات التابعة لها الصلاحية في استخدام بعض المعلومات والبيانات الشخصية (كأسم المستخدم في صفحات الاجتãعية وصورة<br />" +
        "الحساب الشخصي) في هذه الصفحة الالكترونية لغرض الترويج والتطوير. جميع الحقوق التي ذكرة يحتفظ بها مجموعة شركات الكرونجي والشركات التابعة.<br />" +
        "في<br />" +
        "مجموعة شركات الكرونجي والشركات التابعة لا تحمل المسؤولية بشأن اي مشاكل او خلافات او سرية المعلومات الشخصية ب· المستخدم لهذه الصفحة وب·<br />" +
        "اي رابط لصفحة الكترونية من خلال هذه الصفحة. لهذه نحن ننصح بأتخاذ اي تدابò لازمة للحفاظ على سرية المعلومات الشخصية للزائر او المستخدم قبل<br />" +
        "الولوج الى اي صفحة الكترونية من خلال رابط متواجد في هذه الصفحة وادخال معلومات التي تعتبر خاصة بالمتصفح.",

    "category.view_products": "عرض المنتجات",
    "category.back_products": "رجوع",

    "contacts.address": "عناويننا",
    "contacts.phones": "رقم الهاتف",
    "contacts.emails": "البريد الالكتروني",


    "careers.title": "وظائف",
    "careers.apply": "تقديم طلب وظيفة",
    "careers.form.fullname": "الاسم الكامل",
    "careers.form.email": "البريد الالكتروني",
    "careers.form.phone": "رقم الهاتف",
    "careers.form.jobtitle": "عنوان الوظيفة",
    "careers.form.location": "مكان الإقامة",
    "careers.form.uploadcv": "السيرة الذاتية",
    "careers.form.send": "إرسال",

    "careers.jobs.jobTitle": "عنوان الوظيفة",
    "careers.jobs.jobDescription": "تفاصيل",
    "careers.jobs.jobSalary": "الراتب الشهري",
    "careers.jobs.jobLocation": "مكان الوظيفة",

    "careers.unspecified": "غير محدد",
    "careers.success.title": "تم",
    "careers.success.message": "لقد تم إرسال طلبك",

    "contactus.title": "تواصل معنا",
    "contactus.form.message": "تفاصيل",
    "contactus.form.send": "إرسال"
}



