import React, {useEffect, useState} from 'react';
import './certificates.scss';
import {connect} from "react-redux";
import {FooterStates, PageStates} from "../../../../reducers/connection/states";
import {FooterDispatch, PageDispatch} from "../../../../reducers/connection/functions";
import {Input, Button, Radio, Icon} from 'antd';
import Images from '../../../../constants/Images'

import 'antd/dist/antd.css'
import {FormattedMessage} from "react-intl";
import {animated} from "react-spring";

const Certificates = ({match,children, change_theme, themeColor, isDirectionRTL,currentRoute,routeChanged,themeFooterBackgroundGradient, themeFooterLineGradient, themePageBackgroundGradient}) => {

    const [leftAnimation,setLeftAnimation] = useState(isDirectionRTL?"slideInRight":"slideInLeft");
    const [rightAnimation,setRightAnimation] = useState(isDirectionRTL?"slideInLeft":"slideInRight");


    useEffect(() => {
        if(routeChanged && currentRoute != match.path){
            setLeftAnimation(isDirectionRTL?"slideOutEasingRight":"slideOutEasingLeft");
            setRightAnimation(isDirectionRTL?"slideOutEasingLeft":"slideOutEasingRight");
        }
    }, [routeChanged]);

    const arabicClass = isDirectionRTL ? "page-contents page-contents-rtl" : "page-contents page-contents-ltr";


    return (
        <div className={"container-fluid "+arabicClass} id={"certificates"}>

            <div className={"row"}>
                <div className={"col-lg-5 col-md-5 col-sm-12 section animated "+leftAnimation}>

                    <div className={"section-first-container"}>
                        <div className={"career-title"}>
                            <FormattedMessage tagName={"h3"} id={"home.certificateTitle"}/>
                        </div>
                        <div className={"job-details"}>
                            <FormattedMessage id="home.certificatesLongDescription">
                                {text=>
                                    <span dangerouslySetInnerHTML={{__html: text}} />
                                }
                            </FormattedMessage>
                        </div>
                    </div>

                </div>
                <div className={"col-lg-7 col-md-7 col-sm-12 section animated "+rightAnimation}>
                    <div className={"section-second-container"}>

                        <div className={"form-title"}>
                            <FormattedMessage tagName={"h1"} id={"home.certificateTitle"}/>
                        </div>
                        <div className="certificates-container">
                            <ul>
                                {
                                    Images.certificates.map((certificate,index)=>{
                                        return(
                                            <li>
                                                <img src={certificate} />
                                            </li>
                                        )
                                    })
                                }
                            </ul>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}


export default connect(PageStates, PageDispatch)(Certificates);
