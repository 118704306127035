import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Data from './Data';
import Theme from './Theme';
import Route from './Route';


export default (history) => combineReducers({
    router: connectRouter(history),
    data: Data,
    theme: Theme,
    route: Route

});
