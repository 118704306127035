import React, {useEffect, useState} from 'react';
import './bubbles.scss';
import {connect} from "react-redux";
import {FooterStates, PageStates} from "../../reducers/connection/states";
import {FooterDispatch, PageDispatch} from "../../reducers/connection/functions";
import {animated} from "react-spring";
import ReactPlayer from "react-player";
import Images from "../../constants/Images";


const Bubbles = ({children, viewCategories, location, selectedCategory}) => {

    const [renderComponent, setRenderComponent] = useState(true);
    const [blendMode, setBlendMode] = useState("unset");


    const Motion = ({
                        counter = 4,
                        animationMode=null,
                        onePiece = false,
                        images = false,
                        multiSizes = false,
                        _blendMode="",
                        category = true,
                        specialKey = "motion"
                    }) => {
        let table = [];


        useEffect(()=>{
            setBlendMode(_blendMode);
        },[_blendMode]);


        const { detect } = require('detect-browser');
        const browser = detect();



        let className = "browser_"+browser.name+ " animationItem";

        if (animationMode === "fromBottom") {
            className += " itemsFromBottom"
        } else if (animationMode === "fromMiddle") {
            className += " itemsFromMiddle"
        } else if (animationMode === "fromTop") {
            className += " itemsFromTop"
        } else if (animationMode === "inMiddle") {
            className += " sliding animated"
        } else if (animationMode === "bubbles") {
            className += " bubbling animated"
        } else if (animationMode === "swing") {
            className += " swing animated"
        }

        let imagesClass = images ? "animationItemImage" : "";


        if (onePiece) {
            imagesClass += " onePiece";
        }
        if (multiSizes) {
            imagesClass += " multiSizes";
        }


        var i = 0;
        while (i < counter) {
            if (category && images && selectedCategory && Images.categories[selectedCategory.slug] && Images.categories[selectedCategory.slug].motion) {
                Images.categories[selectedCategory.slug][specialKey].map((img, index) => {
                    table.push(
                        <div key={"bubbles_motion_" + i + "_" + index} className={className + " " + imagesClass}>
                            <img src={img}/>
                        </div>
                    )
                })
            } else {
                Images.categories.k_cola.motion.map((img, index) => {
                    table.push(
                        <div style={{mixBlendMode:blendMode}} key={"bubbles_motion_" + i + "_" + index} className={className + " " + imagesClass}>
                            <img src={img}/>
                        </div>
                    )
                })
            }
            i++;
        }


        return table
    }


    const slug = selectedCategory ? selectedCategory.slug : "";

    const catName = location.pathname.split("/")[1];


    const displayBubbles = !viewCategories ? "animationContainer animated zoomIn" : "animationContainer animated zoomOut";

    useEffect(() => {
        setTimeout(() => {
            viewCategories ? setRenderComponent(false) : setRenderComponent(true);
        }, 1000)
    }, [viewCategories]);




    if (renderComponent && catName == "category" && Images.categories.hasOwnProperty(slug) && Images.categories[slug].hasOwnProperty("motion")) {
        return (
            <div data-html={document.getElementsByClassName("animationItem").length} style={{mixBlendMode:blendMode,padding: 0}} className={displayBubbles}>


                {(slug.indexOf("rich") !== -1 ||
                    slug.indexOf("cola") !== -1 || slug.indexOf("malt") !== -1) && <Motion counter={10} animationMode={"bubbles"} _blendMode={"soft-light"} images multiSizes/>}

                {(slug === "karwnchi_water" || slug==="tonic" || slug === "mina") && <Motion counter={10} animationMode={"bubbles"} _blendMode={"soft-light"} images multiSizes/>}

                {(slug.indexOf("nakoo") !== -1) &&
                <Motion counter={50} animationMode={"fromBottom"} images multiSizes _blendMode={"overlay"}/>}

                {/*{(slug.indexOf("malt") !== -1) &&*/}
                {/*<Motion counter={1} animationMode={"inMiddle"} onePiece images/>}*/}

                {(slug.indexOf("tazech_juice") !== -1) &&
                <Motion counter={1} animationMode={"inMiddle"} onePiece images/>}


                {(slug === "zbeeb") &&
                <Motion counter={5} animationMode={"fromMiddle"} images multiSizes/>}
                {(slug === "valencia" || slug === "friday") &&
                <Motion counter={20} animationMode={"fromMiddle"} images multiSizes/>}


                {(slug === "zbeeb") &&
                <Motion counter={5} animationMode={"swing"} specialKey={"swing"} images/>}

                {(slug === "tonic") &&
                <Motion counter={5} animationMode={"swing"} specialKey={"swing"} images/>}

                {(slug.indexOf("tazech_juice") !== -1) &&
                <Motion counter={1} animationMode={"inMiddle"} specialKey={"cat1"} onePiece images/>}

                {(slug.indexOf("tazech_juice") !== -1) &&
                <Motion counter={1} animationMode={"inMiddle"} specialKey={"cat2"} onePiece images/>}

                {(slug === "natural_water") &&
                <Motion counter={1} animationMode={"inMiddle"} specialKey={"ice1"} onePiece images/>}
                {(slug === "natural_water") &&
                <Motion counter={1} animationMode={"inMiddle"} specialKey={"ice2"} onePiece images/>}
                {/*{slug == "malt" || slug == "fri_dai_friday" && createBubbles(2, false, false, false, true, true)}*/}
            </div>
        )
    } else {
        return (
            <div className={displayBubbles} style={{padding: 0,mixBlendMode:blendMode}}><Motion counter={10} animationMode={"bubbles"} images multiSizes _blendMode={"soft-light"}
                                                                         category={false}/></div>)
    }

}


export default connect(PageStates, PageDispatch)(Bubbles);
