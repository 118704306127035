import React, {useEffect, useState} from 'react';
import './productInfo.scss';
import {connect} from "react-redux";
import {CategoryStates} from "../../reducers/connection/states";
import {CategoryDispatch} from "../../reducers/connection/functions";
import CloseIcon from '@material-ui/icons/Close';

const _product = {
    "id": "b59cd9cf-a64f-4556-a1b6-da3e78fc1ab6",
    "image_file": "sig_combibloc_juice__(180_ml)_5e3427e9dd9c0.png",
    "category_id": "1b2c002a-38fc-452a-90a1-38f026049ca9",
    "added_by": "7dd509a0-3d3a-11ea-bfbf-a3ff7d24a30a",
    "created_at": "2020-01-31 13:13:14",
    "updated_at": "2020-01-31 13:13:14",
    "active": 1,
    "product_name": {
        "tr": "",
        "ku": "",
        "en": "",
        "ar": ""
    },
    "product_details": {
        "tr": "",
        "ku": "",
        "en": "",
        "ar": ""
    },
    "category_name": "Cup Juice (200 ml)",
    "image_file_full": "",
    "ingredients": [

    ]
}

const ProductInfo = ({locale, open,select_product, isDirectionRTL,selectedProduct}) => {

    const [productDetails,setProductDetails] = useState(_product);
    const [enter,setEnter] = useState(true);


    const side = isDirectionRTL?"Left":"Right";

    const openClassName = selectedProduct?"slideIn"+side:"slideOut"+side;
    const openContentsClassName = enter?["slideIn"+side,"slideIn"+side,"slideIn"+side]:["slideOut"+side,"slideOut"+side,"slideOut"+side];


    useEffect(()=>{
        if(selectedProduct){
            setEnter(false);
            setTimeout(()=>{
                setProductDetails(selectedProduct);
                setEnter(true);
            },500);
        }
    },[selectedProduct]);

    const arabicClass = isDirectionRTL?"productInfoRTL":"productInfoLTR";

    const checkName = (name,number) => {
        const splitName = name.split(" ");

        const hs = splitName.filter((word)=>{
            return word.length >= number;
        });


        return hs.length;
    }

    const productName = productDetails && productDetails.hasOwnProperty("product_name") ? productDetails.product_name[locale.locale]:"";
    let cssClass = "normalSize";
    if(checkName(productName,13)){
        cssClass = "small";
    }else if(checkName(productName,11)){
        cssClass = "large";
    }else if(checkName(productName,9)){
        cssClass = "xlarge";
    }

    return (
        <div id={"productInfo"} hidden={!selectedProduct} className={"animated "+openClassName+" "+arabicClass}>

            <div onClick={()=>{
                select_product(null);
            }} className={"close-btn"}>
                <CloseIcon fontSize={"large"} color={"inherit"} />
            </div>
            <div className={"product_details"}>

                <div className={"product-img"}>
                    <img className={"animated fast "+openContentsClassName[0]} src={productDetails.hasOwnProperty("image_file_full") && productDetails.image_file_full}/>
                </div>

                <div className={"product-title animated fast "+openContentsClassName[1]+" "+cssClass}>
                    <h3>{productName}</h3>
                </div>
                <div className={"product-ingredients animated fast "+openContentsClassName[2]}>
                    <ul>
                        {
                            productDetails.ingredients.map((ingredient,index) => {
                                return (
                                    <li key={"ingredient_"+index}>
                                        <span>{ingredient.name}</span>
                                        <span>{ingredient.number} ml</span>
                                    </li>
                                )
                            })
                        }
                    </ul>

                </div>

            </div>


        </div>

    )
}


export default connect(CategoryStates, CategoryDispatch)(ProductInfo);
