//App
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const FETCH_USERS = 'FETCH_USERS';
export const CHANGE_DISPLAY_MODE = 'CHANGE_DISPLAY_MODE';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_SETTINGS = 'FETCH_SETTINGS';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const FETCH_NEWSLETTERS = 'FETCH_NEWSLETTERS';
export const FETCH_CAREERS = 'FETCH_CAREERS';
export const ADDED_SOMETHING = 'ADDED_SOMETHING';

export const ROUTE_CHANGED = 'ROUTE_CHANGED';


export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_THEME_COLOR = 'CHANGE_THEME_COLOR';
export const CHANGE_FOOTER_BACKGROUND = 'CHANGE_FOOTER_BACKGROUND';
export const CHANGE_FOOTER_LINE_GRADIENT = 'CHANGE_FOOTER_LINE_GRADIENT';
export const CHANGE_PAGE_BACKGROUND_GRADIENT = 'CHANGE_PAGE_BACKGROUND_GRADIENT';


