import React, {useEffect, useState} from 'react';
import './contact.scss';
import {connect} from "react-redux";
import {FooterStates, PageStates} from "../../../../reducers/connection/states";
import {FooterDispatch, PageDispatch} from "../../../../reducers/connection/functions";
import {Input} from 'antd'
import {Button, Radio, Icon} from 'antd';

import 'antd/dist/antd.css'
import {FormattedMessage} from "react-intl";
import IntlMessages from "../../../../util/IntlMessages";
import axios from "axios";
import APIs from "../../../../constants/ApiURL";
import swal from "sweetalert";

const Contact = ({match, isDirectionRTL, locale, children, change_theme, themeColor, themeFooterBackgroundGradient, themeFooterLineGradient, themePageBackgroundGradient, routeChanged, currentRoute}) => {

    const [leftAnimation, setLeftAnimation] = useState(isDirectionRTL ? "slideInRight" : "slideInLeft");
    const [rightAnimation, setRightAnimation] = useState(isDirectionRTL ? "slideInLeft" : "slideInRight");

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");


    useEffect(() => {
        const iframe = document.querySelector('#map-iframe');

// using reference to iframe (ifrm) obtained above
        const win = iframe.contentWindow; // reference to iframe's window
// reference to document in iframe
        const doc = iframe.contentDocument ? iframe.contentDocument : iframe.contentWindow.document;
// reference to form named 'demoForm' in iframe
        const form = doc.getElementsByClassName('i4ewOd-pzNkMb-haAclf');

        if(form.length){
            form[0].style.display = 'none';
        }


    }, []);


    useEffect(() => {
        if (routeChanged && currentRoute != match.path) {
            setLeftAnimation(isDirectionRTL ? "slideOutEasingRight" : "slideOutEasingLeft");
            setRightAnimation(isDirectionRTL ? "slideOutEasingLeft" : "slideOutEasingRight");
        }

    }, [routeChanged]);


    const validate = () => {

        const failedTitles = {
            ar: "للاسف",
            en: "Sorry",
            ku: "للاسف",
            tr: "Sorry",
        }
        const failedTexts = {
            ar: "يرجى تعبئة جميع الحقول المطلوبة",
            en: "You must fill all required fields",
            ku: "يرجى تعبئة جميع الحقول المطلوبة",
            tr: "You must fill all required fields",
        }
        if (fullName == "" || email == "") {

            swal({
                title: failedTitles[locale.locale],
                text: failedTexts[locale.locale],
                icon: "error",
            });
            return false;
        }

        return true;
    }


    const sendForm = () => {


        if (!validate()) return;


        const successTitles = {
            ar: "تم",
            en: "Done",
            ku: "تم",
            tr: "Done",
        }
        const failedTitles = {
            ar: "للاسف",
            en: "Sorry",
            ku: "للاسف",
            tr: "Sorry",
        }
        const successTexts = {
            ar: "لقد تم إرسال راسلتك بنجاح",
            en: "Message has been sent successfully",
            ku: "لقد تم إرسال راسلتك بنجاح",
            tr: "Message has been sent successfully",
        }
        const failedTexts = {
            ar: "حدث خطأ لم يتم إرسال راسلتك",
            en: "Message has not sent",
            ku: "حدث خطأ لم يتم إرسال راسلتك",
            tr: "Message has not sent",
        }
        const formData = new FormData();
        formData.append("fullname", fullName);
        formData.append("email", email);
        formData.append("message", message);

        axios.post(APIs.sendContactUs, formData).then(() => {
            swal({
                title: successTitles[locale.locale],
                text: successTexts[locale.locale],
                icon: "success",
            });

        }).catch(() => {
            swal({
                title: failedTitles[locale.locale],
                text: failedTexts[locale.locale],
                icon: "error",
            });
        })
    }

    const arabicClass = isDirectionRTL ? "container-fluid page-contents page-contents-rtl" : "container-fluid page-contents page-contents-ltr";


    return (
        <div className={arabicClass} id={"contact"}>

            <div className={"row"}>
                <div className={"col-lg-6 col-md-12 col-sm-12 section animated " + leftAnimation}>

                    <div className={"section-first-container"}>
                        <div className={"career-title"}>
                            <h3><IntlMessages id={"contactus.title"}/></h3>
                        </div>
                        <div className={"job-details"}>
                            <h3><IntlMessages id={"contacts.address"}/></h3>
                            <span>Iraq, Kirkuk, Altun Kupri</span>
                        </div>
                        <div className={"job-details"}>
                            <h3><IntlMessages id={"contacts.phones"}/></h3>
                            <span>
                                +964 750 317 0999<br/>
                                +964 770 859 9559
                            </span>
                        </div>
                        <div className={"job-details"}>
                            <h3><IntlMessages id={"contacts.emails"}/></h3>
                            <span>info@karwanchigroup.com</span>
                        </div>
                    </div>

                </div>
                <div className={"col-lg-6 col-md-12 col-sm-12 section animated " + rightAnimation}>
                    <div className={"section-second-container"}>
                        <form autoComplete={false}>
                            <div className={"form-title"}>
                                <h1><IntlMessages id={"contactus.title"}/></h1>
                            </div>
                            <div className="form-inputs">
                                <FormattedMessage id="careers.form.fullname" defaultMessage="search">
                                    {placeholder =>
                                        <Input onChange={v => {
                                            setFullName(v.currentTarget.value)
                                        }} size="small" placeholder={placeholder + " *"}/>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="careers.form.email" defaultMessage="search">
                                    {placeholder =>
                                        <Input onChange={v => {
                                            setEmail(v.currentTarget.value)
                                        }} size="small" placeholder={placeholder + " *"}/>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="contactus.form.message" defaultMessage="search">
                                    {placeholder =>
                                        <Input.TextArea onChange={v => {
                                            setMessage(v.currentTarget.value)
                                        }} size="small" placeholder={placeholder} rows={2}/>
                                    }
                                </FormattedMessage>

                                <div className={"apply-btn"}>
                                    <button onClick={sendForm} type={"button"}><IntlMessages
                                        id={"contactus.form.send"}/></button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>

            </div>
            <div className={"row"}>
                <div className={"col-lg-12"}>
                    <iframe
                        id={"map-iframe"}
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d75519.31814845439!2d44.18454246542264!3d35.711237371650256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzXCsDQ0JzI5LjQiTiA0NMKwMTAnMjIuNSJF!5e0!3m2!1sen!2sau!4v1585059256913!5m2!1sen!2sau"
                        style={{width: "100%", height: 450, marginTop: 20}} frameBorder="0" allowFullScreen=""
                        aria-hidden="false" tabIndex="0"/>

                </div>
            </div>
        </div>
    )
}


export default connect(PageStates, PageDispatch)(Contact);
