import enLang from './entries/en-US';
import kuLang from './entries/ku-IQ';
import arLang from './entries/ar_SA';
import trLang from './entries/tr-TR';
import {addLocaleData} from 'react-intl';

const AppLocale = {
  en: enLang,
  // ku: kuLang,
  ar: arLang,
  // tr: trLang,
};
addLocaleData(AppLocale.en.data);
// addLocaleData(AppLocale.tr.data);
addLocaleData(AppLocale.ar.data);
// addLocaleData(AppLocale.ku.data);

export default AppLocale;
