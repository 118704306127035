import React from 'react';
import {connect} from "react-redux";
import {CategoryStates} from "../../reducers/connection/states";
import {CategoryDispatch} from "../../reducers/connection/functions";
import Images from "../../constants/Images";
import './corners.scss';

const Corner = ({match, isDirectionRTL, viewCategories, change_display_mode, selectedProduct, select_product, locale, selectedCategory, routeChanged, currentRoute}) => {


    const {detect} = require('detect-browser');
    const browser = detect();

    const categoryImages = selectedCategory &&  Images.categories.hasOwnProperty(selectedCategory.slug) ? Images.categories[selectedCategory.slug]:{};

    const arabicClass = isDirectionRTL ? "corner-item corner-bottom-right animated fadeIn rtl" : "corner-item corner-bottom-left animated fadeIn ltr";

    return (

        <>
            {
                categoryImages.hasOwnProperty("corners") && categoryImages.corners.map((corn) => {

                    return (
                        <div className={arabicClass}>
                            <img src={corn.img}/>
                        </div>
                    )
                })
            }
        </>


    )
};

export default connect(CategoryStates, CategoryDispatch)(Corner);
