import React, {useEffect, useState} from 'react';
import './static.scss';
import PageBackground from "./PageBackground";
import NavBar from "./NavBar";
import SocialMedia from "./Social";
import Footer from "./Footer";
import windowSize from 'react-window-size';



const Static = ({children,match, location,history,windowWidth}) => {


    return (
        <PageBackground location={location}>
            <NavBar location={location} history={history}/>


            {children}
            <SocialMedia />

            <Footer location={location} history={history}/>
        </PageBackground>

    )
}


export default windowSize(Static);
