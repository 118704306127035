import {
    CHANGE_FOOTER_BACKGROUND,
    CHANGE_FOOTER_LINE_GRADIENT, CHANGE_LANGUAGE,
    CHANGE_PAGE_BACKGROUND_GRADIENT,
    CHANGE_THEME_COLOR,
} from "../constants/ActionTypes";
import categoriesThemes from '../constants/ThemeColors';


const INIT_STATE = {

    themeColor: "rgba(180, 2, 12, 1)",
    themeFooterBackgroundGradient: "radial-gradient(circle, rgba(223, 6, 31, 1) 0%, rgba(223, 6, 31, 1) 3%, rgba(213, 5, 26, 1) 7%, rgba(180, 2, 12, 1) 26%, rgba(160, 0, 3, 1) 44%, rgba(154, 0, 0, 1) 85%, rgba(154, 0, 0, 1) 100%)",
    themePageBackgroundGradient: "radial-gradient(circle, rgba(223,6,31,1) 0%, rgba(223,6,31,1) 3%, rgba(187,2,15,1) 26%, rgba(154,0,0,1) 85%, rgba(154,0,0,1) 100%)",
    isDirectionRTL: false,
    locale: {
        languageId: 'english',
        locale: 'en',
        name: 'English',
        icon: 'us'
    }
};
/*
{
    languageId: 'arabic',
        locale: 'ar',
    name: 'Arabic',
    icon: 'sa'
}
*/

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_THEME_COLOR: {

            let payload = action.payload;
            const {themeColor, themeFooterBackgroundGradient, themePageBackgroundGradient} = payload;
            return {
                ...state,
                themeColor: themeColor,
                themeFooterBackgroundGradient: themeFooterBackgroundGradient,
                themePageBackgroundGradient: themePageBackgroundGradient

            }
        }
        case CHANGE_LANGUAGE: {

            let payload = action.payload;
            let rtl = payload.rtl;
            let locale = payload.locale;
            let label = payload.label;

            return {
                ...state,
                isDirectionRTL: rtl,
                locale: {
                    languageId: label,
                    locale: locale,
                    name: label,
                    icon: locale
                },

            }
        }
        default:
            return state;
    }
}
