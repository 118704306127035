import appLocaleData from 'react-intl/locale-data/ar';
import saMessages from '../locales/ar_SA';

const saLang = {
  messages: {
    ...saMessages
  },
  rtl:true,
  label:"عريي",
  locale: 'ar',
  data: appLocaleData
};
export default saLang;
